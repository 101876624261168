import { useEffect, useRef, useState } from "react";

/* Uses ref to ensure callback always has the latest state */
const useStateRef = (initialValue) => {
  const [currentValue, setCurrentValue] = useState(initialValue);

  const ref = useRef(currentValue);

  useEffect(() => {
    ref.current = currentValue;
  }, [currentValue]);

  return [currentValue, setCurrentValue, ref];
};

export default useStateRef;
