import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

/* Hook that gives us a ref to the selected redux state value */
const useReduxSelectorRef = (selector) => {
  const data = useSelector(selector);
  const ref = useRef(data);

  useEffect(() => {
    ref.current = data;
  }, [data]);

  return [data, ref];
};

export default useReduxSelectorRef;
