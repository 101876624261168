export const conversationActionEnum = Object.freeze({
  resolveConversation: "RESOLVE_CONVERSATION",
  transferConversation: "TRANSFER_CONVERSATION",
  createConversationNote: "CREATE_CONVERSATION_NOTE",
  createConversationMessage: "CREATE_CONVERSATION_MESSAGE",
  updateConversationMessageAsRead: "UPDATE_CONVERSATION_MESSAGE_AS_READ",
});

export const conversationInitiationStatusEnum = Object.freeze({
  pending: 0,
  preparingInitiationMessage: 1,
  readyToInitiate: 2,
  created: 3,
  linkMpc: 4,
});

export const conversationSidePaneSectionEnum = Object.freeze({
  contactInformation: 1,
  contextPanels: 2,
  sharedMedia: 3,
  eventsTimeline: 4,
  pastConversations: 5,
  paymentSessions: 6,
  contentLinks: 7,
});

export const conversationTransferTypeEnum = Object.freeze({
  directTransfer: "directly to",
  autoAssign: "automatically",
});

export const callTypeEnum = Object.freeze({
  VIDEO_CALL: "VIDEO_CALL",
  VOICE_CALL: "VOICE_CALL",
});

export const conversationCreatedSourceEnum = Object.freeze({
  MESSAGE_BLAST: "MESSAGE_BLAST",
  TEST_MESSAGE_BLAST: "TEST_MESSAGE_BLAST",
});
