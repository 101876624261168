import { useRef } from "react";

const useInitial = (value) => {
  const ref = useRef();

  if (ref.current == undefined) {
    ref.current = value;
  }

  return ref.current;
};

export default useInitial;
