import { useCallback, useEffect, useRef, useState } from "react";

import { useValueRef } from "@/utils/hookUtils";

const messageTypes = {
  lockFeature: "lock-feature",
  syncDataRequest: "sync-data-request",
  syncDataResponse: "sync-data-response",
};

const useFeatureLeaderElector = ({
  featureName,
  currentTabId,
  isLeaderTab,
  lastVisibleTabIdStorageData,
}) => {
  const featureBroadcastChannelRef = useRef();

  const [tabUsingFeature, setTabUsingFeature] = useState(null);

  const isAnyTabUsingFeature = !!tabUsingFeature;
  const isTabUsingFeature = currentTabId === tabUsingFeature;
  const isFeatureUsedInAnotherTab = isAnyTabUsingFeature && !isTabUsingFeature;

  const hasLastVisibleTab = !!lastVisibleTabIdStorageData;
  const isLastVisibleTab = currentTabId === lastVisibleTabIdStorageData;

  const isFeatureLeaderTab = (() => {
    if (isAnyTabUsingFeature) return isTabUsingFeature;
    if (hasLastVisibleTab) return isLastVisibleTab;
    return isLeaderTab;
  })();

  const valueRefs = useValueRef({
    isFeatureLeaderTab,
    currentTabId,
    tabUsingFeature,
  });

  const handleFeatureLock = useCallback(
    ({ isFeatureInUse }) => {
      const { isFeatureLeaderTab, currentTabId } = valueRefs.current;

      if (!isFeatureLeaderTab) return;
      if (!featureBroadcastChannelRef.current) return;

      const newTabId = isFeatureInUse ? currentTabId : null;

      setTabUsingFeature(newTabId);
      featureBroadcastChannelRef.current.postMessage({
        type: messageTypes.lockFeature,
        tabId: newTabId,
      });
    },
    [valueRefs],
  );

  /* This effect will set up tab communication to keep data about the feature lock in sync */
  useEffect(() => {
    const channelName = `${featureName}-channel`;
    const channel = new BroadcastChannel(channelName);

    channel.onmessage = (message) => {
      const { data } = message;

      switch (data.type) {
        case messageTypes.lockFeature: {
          setTabUsingFeature(data.tabId);
          return;
        }

        case messageTypes.syncDataRequest: {
          if (!valueRefs.current.isFeatureLeaderTab) return;

          channel.postMessage({
            type: messageTypes.syncDataResponse,
            tabUsingFeature: valueRefs.current.tabUsingFeature,
          });
          return;
        }

        case messageTypes.syncDataResponse: {
          setTabUsingFeature(data.tabUsingFeature);
          return;
        }

        default: {
          return;
        }
      }
    };

    channel.postMessage({ type: messageTypes.syncDataRequest });
    featureBroadcastChannelRef.current = channel;

    return () => {
      channel.close();
      featureBroadcastChannelRef.current = null;
    };
  }, [valueRefs, featureName]);

  return {
    isFeatureLeaderTab,
    isFeatureUsedInAnotherTab,
    handleFeatureLock,
  };
};

export default useFeatureLeaderElector;
