import * as sharedConversationDefinitions from "../sharedConversationDefinitions";

/*
  This is a shared field used in both chat panel and conversation list.
  This prevent unexpected reload when outbound call is started quickly before receiving VOICE_CALL_RECORDING_PLAYBACK_READY event.
*/
export const sharedInitiatorVoiceLegDefinition = `{
    id
    agent {
        id
    }
    voiceProviderAccount {
        id
        name
    }
}`;

/*
  Important: This is a shared field. Only add fields that appear/are used in:
  - ConversationEventSubscription.js
  - ConversationCardContainer.js
  - Chat panel components eg. JoinVoiceCallCard / MonitorVoiceCallCard
*/
export const sharedCurrentParticipantsDefinition = `
  isActive
  isCallMonitor
  participantType
  agent ${sharedConversationDefinitions.agentDefinition}
  voiceProviderContact ${sharedConversationDefinitions.voiceProviderContactDefinition}
`;

const currentParticipantsDefinition = `{
  ${sharedCurrentParticipantsDefinition}
  isMuted
  isCallAnswered
  voiceLeg {
    id
    created
  }
}`;

const conversationDefinition = `{
  id
  conversationTags ${sharedConversationDefinitions.conversationTagsDefinition}
  group ${sharedConversationDefinitions.groupDefinition}
  
  assignee {
    id
  }
  
  contact {
    id
    fullName
    phoneNumber
    displayName
    contactTags {
      id 
      contentTypeAllowedTag ${sharedConversationDefinitions.contentTypeAllowedTagDefinition}
    }
  }
}`;

/*
  Important: This is a shared field. Only add fields that appear/are used in:
  - ConversationEventSubscription.js
  - ConversationCardContainer.js
  - Chat panel components eg. JoinVoiceCallCard / MonitorVoiceCallCard
*/
export const sharedLastVoiceConversationDefinition = `
  id
  answeredAt
  voiceCallStatus: conversationStatus
  
  lastVoiceLegOfMe {
    id
    legType
  }
`;

export const lastVoiceConversationDefinition = `{
  ${sharedLastVoiceConversationDefinition}
    isOnHold
    answeredByMachineAt
    lastOnHoldAt
    initiatorVoiceLeg ${sharedInitiatorVoiceLegDefinition}
    currentParticipants ${currentParticipantsDefinition} 
    conversation ${conversationDefinition}
    voiceProviderContact ${sharedConversationDefinitions.voiceProviderContactDefinition}
    
    voiceProviderConversation {
      id
      clientConversationId
    }
    
    lastAgentIpCallToMe {
      id
      batch {
        id
        callSequence {
          id
          isSelfInvite
          sequenceType
          targetAgent {
            id
          }
          targetGroup {
            id
          }
        }
      }
      voiceProviderUserIpCall {
        id
        clientIpCallId
      }
    }
  }`;
