import { gql } from "@apollo/client";

export const GET_ADDITIONAL_USER_DATA = gql`
  query GetAdditionalUserData {
    messagingProviders(isInUse: true) {
      id
      name
      messagingProviderLabel
    }

    messagingProviderAccounts {
      id
      name
      messagingProvider {
        id
        name
      }
    }
  }
`;
