import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import useInterval from "./useInterval";

const useStopwatch = (time, unit = "seconds", interval = 1) => {
  const [seconds, setSeconds] = useState(0);

  const duration = useMemo(
    () => moment.duration(interval, unit).asMilliseconds(),
    [interval, unit],
  );

  useEffect(() => {
    setSeconds(() => moment().diff(time, unit));
  }, [time, unit]);

  useInterval(
    () => setSeconds((prevSeconds) => prevSeconds + interval),
    duration,
  );

  return seconds;
};

export default useStopwatch;
