export const greetingTypeEnum = Object.freeze({
  AFTER_HOURS: "AFTER_HOURS",
  BUSINESS_HOURS: "BUSINESS_HOURS",
  CUSTOM: "CUSTOM",
  VOICEMAIL: "VOICEMAIL",
  IVR_NODE_RETRY: "IVR_NODE_RETRY",
  IVR_NODE_REMINDER: "IVR_NODE_REMINDER",
  IVR_NODE_FALLBACK: "IVR_NODE_FALLBACK",
  CALL_QUEUE_ENTER: "CALL_QUEUE_ENTER",
  CALL_QUEUE_DELAY: "CALL_QUEUE_DELAY",
  CALL_QUEUE_FULL: "CALL_QUEUE_FULL",
  CALL_QUEUE_TIMEOUT: "CALL_QUEUE_TIMEOUT",
  CALL_QUEUE_MENU: "CALL_QUEUE_MENU",
});

export const voiceCallSettingsTypeEnum = Object.freeze({
  GLOBAL: "global",
});

export const voiceActionTypeEnum = Object.freeze({
  CONNECT: "CONNECT",
  SEND_HSM: "SEND_HSM",
  TERMINATE: "TERMINATE",
  QUEUE: "QUEUE",
});

export const voiceConnectActionTypeEnum = Object.freeze({
  AGENT: "AGENT",
  GROUP: "GROUP",
  IVR_NODE: "IVR_NODE",
  VOICEMAIL: "VOICEMAIL",
});
