export const hsmTemplateHeaderBaseTypeEnum = Object.freeze({
  none: "None",
  text: "Text",
  media: "Media",
});

export const hsmTemplateMediaHeaderTypeEnum = Object.freeze({
  IM: "IM",
  VI: "VI",
  DO: "DO",
});

export const hsmTemplateHeaderTypeEnum = Object.freeze({
  TE: "TE",
  ...hsmTemplateMediaHeaderTypeEnum,
});

/* Reference: https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types */
export const hsmMediaHeaderAllowedFileTypesEnum = Object.freeze({
  [hsmTemplateMediaHeaderTypeEnum.IM]: ["image/jpeg", "image/png"],
  [hsmTemplateMediaHeaderTypeEnum.VI]: ["video/mp4", "video/3gp"],
  [hsmTemplateMediaHeaderTypeEnum.DO]: [
    "text/plain",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
});

export const hsmTemplateMediaHeaderFileTypesLabelEnum = Object.freeze({
  [hsmTemplateMediaHeaderTypeEnum.IM]: "jpeg, png",
  [hsmTemplateMediaHeaderTypeEnum.VI]: "mp4, 3gp",
  [hsmTemplateMediaHeaderTypeEnum.DO]: "plaintext, pdf, ppt, doc, excel",
});

export const hsmTemplateMediaHeaderMaxFileSizeEnum = Object.freeze({
  [hsmTemplateMediaHeaderTypeEnum.IM]: 5,
  [hsmTemplateMediaHeaderTypeEnum.VI]: 16,
  [hsmTemplateMediaHeaderTypeEnum.DO]: 100,
});

export const hsmTemplateButtonCategoryEnum = Object.freeze({
  URL: "URL",
  PHONE_NUMBER: "PHONE_NUMBER",
});

export const hsmTemplateButtonTypeEnum = Object.freeze({
  PN: "PN", // Phone Number
  PY: "PY", // Payment
  UR: "UR", // URL
  QR: "QR", // Quick Reply
  VC: "VC", // Visit Content
});

export const hsmTemplateSyncEventEnum = Object.freeze({
  SYNC_HSM_TEMPLATE: "SYNC_HSM_TEMPLATE",
  SYNC_HSM_TEMPLATE_SUCCESS: "SYNC_HSM_TEMPLATE_SUCCESS",
  SYNC_HSM_TEMPLATE_FAILURE: "SYNC_HSM_TEMPLATE_FAILURE",
});

export const hsmTemplateSyncStatusEnum = Object.freeze({
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
});

export const hsmTemplateStatusEnum = Object.freeze({
  APPROVED: "APPROVED",
  IN_APPEAL: "IN_APPEAL",
  PENDING: "PENDING",
  PENDING_DELETION: "PENDING_DELETION",
  REJECTED: "REJECTED",
  DELETED: "DELETED",
  DISABLED: "DISABLED",
  LOCKED: "LOCKED",
});

export const hsmTemplateKindEnum = Object.freeze({
  REGULAR: "REGULAR",
  PAYMENT_LINK: "PAYMENT_LINK",
  LANDING_PAGE: "LANDING_PAGE",
});

export const hsmTemplateCategoryEnum = Object.freeze({
  AUTHENTICATION: "AUTHENTICATION",
  MARKETING: "MARKETING",
  UTILITY: "UTILITY",
});

export const hsmTemplateButtonCategoryTypeEnum = Object.freeze({
  none: "none",
  quickReply: "quick-reply",
  callToAction: "call-to-action",
});
