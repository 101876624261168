import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";

export const conversationReportVarDefinition = `
  $startDatetime: DateTime!
  $endDatetime: DateTime!
  $truncateType: String
  $conversationTagIds: [ID]
  $contactTagIds: [ID]
`;

export const csatFilterVarDefinition = `
  $minScore: Int
  $maxScore: Int
  $withFeedback: Boolean
`;

export const conversationReportArguments = `
  startDatetime: $startDatetime
  endDatetime: $endDatetime
  truncateType: $truncateType
  conversationTagIds: $conversationTagIds
  contactTagIds: $contactTagIds
`;

const csatFilterArguments = `
  minScore: $minScore
  maxScore: $maxScore
  withFeedback: $withFeedback
`;

/* Below are the definitions for conversation reporting */

const averagePerPeriodDefinition = `
  averagePerPeriod {
    datetime
    averageTime
  }
`;

const dataPerPeriodDefinition = `
  dataPerPeriod {
    datetime
    average
  }
`;

export const messagesPerConversationReportMetricsDefinition = `
  metrics {
    averageTotal
    averageOutbound
    averageInbound
    outbound
    inbound
    total
  }
`;

export const reportOverviewDataDefinition = `{
  conversationStartedReport(${conversationReportArguments}) {
    total
  }
  messagesPerConversationReport(${conversationReportArguments}) {
    ${messagesPerConversationReportMetricsDefinition}
  }
  firstResponseTimeIncludeWorkingHourReport(${conversationReportArguments}) {
    average
  }
  responseTimeIncludeWorkingHourReport(${conversationReportArguments}) {
    average
  }
  agentResolutionTimeReport(${conversationReportArguments}) {
    average
  }
  csatReport(${conversationReportArguments}) {
    average
  }
  unattendedConversationReport(${conversationReportArguments}) {
    total
  }
}`;

export const totalNewConversationsDefinition = `
  conversationStartedReport(${conversationReportArguments}) {
    total
    totalPerPeriod {
      datetime
      total
    }
  }
`;

export const totalMessagesDefinition = `
  messagesPerConversationReport(${conversationReportArguments}) {
    outBoundMetrics: metricsPerPeriod {
      datetime
      outbound
    }
    inBoundMetrics: metricsPerPeriod {
      datetime
      inbound
    }
  }
`;

export const averageMessagesPerConversationDefinition = `
  messagesPerConversationReport(${conversationReportArguments}) {
    outBoundMetrics: metricsPerPeriod {
      datetime
      averageOutbound
    }
    inBoundMetrics: metricsPerPeriod {
      datetime
      averageInbound
    }
  }
`;

export const firstResponseTimeIncludeWorkingHourDefinition = `
  firstResponseTimeIncludeWorkingHourReport(${conversationReportArguments}) {
    ${averagePerPeriodDefinition}
  }
`;

export const responseTimeIncludeWorkingHourDefinition = `
  responseTimeIncludeWorkingHourReport(${conversationReportArguments}) {
    ${averagePerPeriodDefinition}
  }
`;

export const agentResolutionTimeDefinition = `
  agentResolutionTimeReport(${conversationReportArguments}) {
    ${averagePerPeriodDefinition}
  }
`;

export const csatReportDefinition = `
  csatReport(${conversationReportArguments} ${csatFilterArguments}) {
    ${dataPerPeriodDefinition}
  }
`;

export const unattendedConversationReportDefinition = `
  unattendedConversationReport(${conversationReportArguments}) {
    totalPerPeriod {
      datetime
      total
    }
  }
`;

export const csatReportDataDefinition = `
  csatReport(
    ${conversationReportArguments} 
    ${csatFilterArguments}
    ) {
    average
    data {
      id
      score
      additionalFeedback {
        id
        messageContentType
        textConversationMessage ${sharedConversationDefinitions.textConversationMessageDefinition}
        mediaConversationMessage ${sharedConversationDefinitions.mediaConversationMessageDefinition}
        locationConversationMessage ${sharedConversationDefinitions.locationConversationMessageDefinition}
      }
      conversation {
        id
        contact {
          id
          fullName
          phoneNumber
        }
        conversationTags {
          id
          contentTypeAllowedTag {
            id
            tag {
              name
            }
          }
        }
        group {
          id
          name
        }
        resolver {
          ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
          ... on BotUserObject  ${sharedConversationDefinitions.senderBotUserDefinition}
        }
        resolvedAt
      }
    }
  } 
`;

export const unattendedConversationReportDataDefinition = `
  unattendedConversationReport(
    ${conversationReportArguments}
    ) {
    total
    data {
      id
      startDatetime
      waitTime
      agent {
        id
        user {
          firstName
          lastName
          email
        }
      }
      contact {
        id
        displayName
        phoneNumber
      }
      conversation {
        id
        status
      }
      group {
        id
        name
      }
    }
  } 
`;

export const conversationReportTableDataDefinition = `
  id
  firstResponseTimeIncludeWorkingHourReport(${conversationReportArguments}) {
    average
  }
  responseTimeIncludeWorkingHourReport(${conversationReportArguments}) {
    average
  }
  messagesPerConversationReport(${conversationReportArguments}) {
    ${messagesPerConversationReportMetricsDefinition}
  }
  conversationStartedReport(${conversationReportArguments}) {
    total
  }
  conversationResolvedReport(${conversationReportArguments}) {
    total
  }
  totalMessagesSentReport(${conversationReportArguments}) {
    total {
      mediaCount
    }
  }
  csatReport(${conversationReportArguments}) {
    average
  }
`;
