export const fileTypeEnum = Object.freeze({
  image: "image",
  video: "video",
  application: "application",
  pdf: "application/pdf",
});

export const fileUploadStatusEnum = Object.freeze({
  inProgress: "inProgress",
  success: "success",
  failed: "failed",
});

/* Source: Backend @ message/settings.py. */
export const supportedOutboundMessageMediaFormatEnum = Object.freeze({
  [fileTypeEnum.image]: ["image/jpeg", "image/png"],
  [fileTypeEnum.video]: ["video/mp4", "video/3gpp", "video/quicktime"],
  [fileTypeEnum.application]: [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "audio/aac",
    "audio/mp4",
    "audio/amr",
    "audio/mpeg",
    "audio/ogg",
    "audio/x-wav",
  ],
});
