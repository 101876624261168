import { createClient } from "graphql-ws";

import { getServerSettings } from "@/utils/serverUtils";

export const createSubscriptionClient = ({
  accessToken,
  connectingCallback,
  connectedCallback,
  closedCallback,
}) => {
  if (!accessToken) return null;

  const { websocketUrl } = getServerSettings();

  /* Create subscription client */
  const subscriptionClient = createClient({
    url: websocketUrl,
    connectionParams: { authToken: `Bearer ${accessToken}` },

    /* 
      If the wait timeout has passed and the server has not responded with ConnectionAck message, 
      the client will terminate the socket by dispatching a close event:
      4504: Connection acknowledgement timeout 
    */
    connectionAckWaitTimeout: 30000,

    /* 
      Retry connection upon all close event or connection error 
      Some common examples (why it is worth retrying):
      1.  1006: Abnormal Closure - Backend server dies. (Might be alive after retry)
      2.  4504: Connection acknowledgement timeout (Might not timeout after retry)
      3.  4004: Bad response - unrecognised 'type' from backend responses 
      4. `4401: Unauthorized - subscribing before ConnectionAck (Might receive ConenctionAck after retry)
    */
    shouldRetry: () => true,

    /* 
      The default retry interval strategy used by graphql-ws is exponential backoff,
      So a large number here doesn't mean we are flooding requests (DDoS), 
      Instead, it is making sure the client keeps on retrying over a long period of time,
      it will be useful in cases such as: backend server dies and back alive afterwards
    */
    retryAttempts: 20,

    on: {
      connecting: (isRetry) => {
        connectingCallback(isRetry);
      },

      connected: (_socket, _payload, wasRetry) => {
        connectedCallback({ wasRetry, subscriptionClient });
      },

      closed: () => {
        closedCallback();
      },
    },
  });

  return subscriptionClient;
};
