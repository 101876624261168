import { tagTypeEnum } from "./tagEnum";

export const reportQueryTypeEnum = Object.freeze({
  conversation: "reporting",
  voice: "voiceReporting",
  messagingOutcomeReporting: "messagingOutcomeReporting",
});

export const reportTypePathEnum = Object.freeze({
  conversation: "conversation",
  voice: "voice",
});

export const sectionQueryTypeEnum = Object.freeze({
  team: "team",
  groups: "groups",
  groupAgent: "groupAgent",
  agents: "agents",
  csat: "csat",
  voiceProviderAccounts: "voiceProviderAccounts",
  messagingOutcomeReport: "messagingOutcomeReport",
});

export const sectionTypePathEnum = Object.freeze({
  team: "team",
  groups: "groups",
  agents: "agents",
  csat: "csat",
  unattended: "unattended",
  did: "did",
  voiceCallLogs: "voiceCallLogs",
  messageOutcome: "messageOutcome",
});

export const reportingFilterTypeEnum = Object.freeze({
  conversationTags: "conversationTags",
  contactTags: "contactTags",
  group: "group",
  agent: "agent",
});

export const voiceCallLogsFilterTypeEnum = Object.freeze({
  callDirection: "callDirection",
  callStarted: "callStarted",
  duration: "duration",
  speedToAnswer: "speedToAnswer",
  status: "status",
  firstAgentInCall: "firstAgentInCall",
});

export const customDurationRangeEnum = Object.freeze({
  oneToFiveMinutes: '{ "durationGte": 1, "durationLte": 300 }',
  fiveToTenMinutes: '{ "durationGte": 300, "durationLte": 600 }',
  tenToThirtyMinutes: '{ "durationGte": 600, "durationLte": 1800 }',
  thirtyToSixtyMinutes: '{ "durationGte": 1800, "durationLte": 3600 }',
  moreThan60Minutes: '{ "durationGte": 3600 }',
});

export const customSpeedToAnswerRangeEnum = Object.freeze({
  withinThirtySeconds: '{ "durationLte": 30 }',
  thirtyToSixtySeconds: '{ "durationGte": 30, "durationLte": 60 }',
  oneToThreeMinutes: '{ "durationGte": 60, "durationLte": 180 }',
  moreThan3Minutes: '{ "durationGte": 180 }',
});

export const tagFilterTypeEnum = Object.freeze({
  [reportingFilterTypeEnum.conversationTags]: tagTypeEnum.CONVERSATION,
  [reportingFilterTypeEnum.contactTags]: tagTypeEnum.CONTACT,
});

export const reportingCSVDataTypeEnum = Object.freeze({
  TEAM_GRAPH: "TEAM_GRAPH",
  GROUP_GRAPH: "GROUP_GRAPH",
  AGENT_GRAPH: "AGENT_GRAPH",
  GROUP_AGENT_GRAPH: "GROUP_AGENT_GRAPH",
  ALL_GROUP_TABLE: "ALL_GROUP_TABLE",
  ALL_AGENT_TABLE: "ALL_AGENT_TABLE",
  GROUP_AGENT_TABLE: "GROUP_AGENT_TABLE",
  TEAM_CSAT: "TEAM_CSAT",
  GROUP_CSAT: "GROUP_CSAT",
  AGENT_CSAT: "AGENT_CSAT",
  GROUP_AGENT_CSAT: "GROUP_AGENT_CSAT",
  TEAM_UNATTENDED: "TEAM_UNATTENDED",
  GROUP_UNATTENDED: "GROUP_UNATTENDED",
  AGENT_UNATTENDED: "AGENT_UNATTENDED",
  GROUP_AGENT_UNATTENDED: "GROUP_AGENT_UNATTENDED",
  VOICE_TEAM_GRAPH: "VOICE_TEAM_GRAPH",
  VOICE_TEAM_INBOUND_GRAPH: "VOICE_TEAM_INBOUND_GRAPH",
  VOICE_TEAM_OUTBOUND_GRAPH: "VOICE_TEAM_OUTBOUND_GRAPH",
  VOICE_ALL_GROUP_TABLE: "VOICE_ALL_GROUP_TABLE",
  VOICE_GROUP_GRAPH: "VOICE_GROUP_GRAPH",
  VOICE_GROUP_INBOUND_GRAPH: "VOICE_GROUP_INBOUND_GRAPH",
  VOICE_GROUP_OUTBOUND_GRAPH: "VOICE_GROUP_OUTBOUND_GRAPH",
  VOICE_GROUP_AGENT_TABLE: "VOICE_GROUP_AGENT_TABLE",
  VOICE_GROUP_AGENT_GRAPH: "VOICE_GROUP_AGENT_GRAPH",
  VOICE_GROUP_AGENT_INBOUND_GRAPH: "VOICE_GROUP_AGENT_INBOUND_GRAPH",
  VOICE_GROUP_AGENT_OUTBOUND_GRAPH: "VOICE_GROUP_AGENT_OUTBOUND_GRAPH",
  VOICE_ALL_AGENT_TABLE: "VOICE_ALL_AGENT_TABLE",
  VOICE_AGENT_GRAPH: "VOICE_AGENT_GRAPH",
  VOICE_AGENT_INBOUND_GRAPH: "VOICE_AGENT_INBOUND_GRAPH",
  VOICE_AGENT_OUTBOUND_GRAPH: "VOICE_AGENT_OUTBOUND_GRAPH",
  VOICE_ALL_DID_TABLE: "VOICE_ALL_DID_TABLE",
  VOICE_DID_GRAPH: "VOICE_DID_GRAPH",
  VOICE_DID_INBOUND_GRAPH: "VOICE_DID_INBOUND_GRAPH",
  VOICE_DID_OUTBOUND_GRAPH: "VOICE_DID_OUTBOUND_GRAPH",
});

export const reportingTruncateTypeEnum = Object.freeze({
  HOUR: "HOUR",
});

export const conversationReportOverviewTabEnum = Object.freeze({
  totalNewConversations: "totalNewConversations",
  totalMessages: "totalMessages",
  averageFirstReplySpeed: "averageFirstReplySpeed",
  averageReplySpeed: "averageReplySpeed",
  averageMessagesPerConversation: "averageMessagesPerConversation",
  averageResolutionTime: "averageResolutionTime",
  averageCSAT: "averageCSAT",
  totalUnattendedConversations: "totalUnattendedConversations",
});

export const messageOutcomeReportOverviewTabEnum = Object.freeze({
  contactsAttemptedCount: "contactsAttemptedCount",
  contactsSuccessfulCount: "contactsSuccessfulCount",
  contactsFailureCount: "contactsFailureCount",
  contactsReadCount: "contactsReadCount",
  contactsPendingCount: "contactsPendingCount",
});

export const voiceReportOverviewTabEnum = Object.freeze({
  inboundCallCount: "inboundCallCount",
  outboundCallCount: "outboundCallCount",
  totalCallTime: "totalCallTime",
  averageCallTime: "averageCallTime",
  totalAnsweredCallCount: "totalAnsweredCallCount",
  totalMissedCallCount: "totalMissedCallCount",
  cancelledCallCount: "cancelledCallCount",
  missedCallCount: "missedCallCount",
  voicemailCount: "voicemailCount",
});

export const voiceReportTypeTabEnum = Object.freeze({
  allCalls: "All calls",
  outboundCalls: "Outbound calls",
  inboundCalls: "Inbound calls",
});

export const messagingOutcomeSectionTabEnum = Object.freeze({
  uniqueContacts: "Unique contacts",
});

export const voiceReportTableTabEnum = Object.freeze({
  allCalls: "All calls",
  uniqueContacts: "Unique contacts",
});
