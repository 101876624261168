import { useEffect, useRef } from "react";

import { empty } from "@/utils/noopUtils";

/*
  This hook is useful when you want to observe changes to the size of any html element and
  run some code as a result of a change in size. It mimics the behavior of the "resize" event
  that exists on the window object
*/
const useResizeObserver = ({
  ref: customRef,
  ignoreResize = false,
  callback = empty,
} = {}) => {
  const ref = useRef();
  const valueRefs = useRef({ callback });

  useEffect(() => {
    const element = customRef?.current || ref?.current;
    if (!element) return;
    if (ignoreResize) return;

    const observer = new ResizeObserver((entries) => {
      valueRefs.current.callback(element, entries[0]);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref, customRef, valueRefs, ignoreResize]);

  return customRef || ref;
};

export default useResizeObserver;
