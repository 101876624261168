export const voiceCallParticipantTypeEnum = Object.freeze({
  agent: "AGENT",
  external: "EXTERNAL",
});

export const voiceCallCandidateTypeEnum = Object.freeze({
  agent: "AGENT",
  contact: "CONTACT",
  newContact: "NEW_CONTACT",
});
