import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useAuthContext } from "@/contextProviders/AuthProvider";
import { useValueRef } from "@/utils/hookUtils";

const useAuthRouteProtection = ({
  isRouteProtected = true,
  onLoggedInCallback,
  onLoggedOutCallback,
} = {}) => {
  const router = useRouter();

  const [isAuthCheckLoading, setIsAuthCheckLoading] = useState(true);

  const { isUserLoggedIn } = useAuthContext();

  const valueRefs = useValueRef({
    router,
    isAuthCheckLoading,
    onLoggedInCallback,
    onLoggedOutCallback,
  });

  useEffect(() => {
    const { router, onLoggedInCallback, onLoggedOutCallback } =
      valueRefs.current;

    setIsAuthCheckLoading(true);

    if (isUserLoggedIn) {
      if (onLoggedInCallback) {
        onLoggedInCallback();
      } else {
        if (!isRouteProtected) {
          router.replace("/inbox");
        }
      }
    } else {
      if (onLoggedOutCallback) {
        onLoggedOutCallback();
      } else {
        if (isRouteProtected) {
          router.replace("/login");
        }
      }
    }

    setIsAuthCheckLoading(false);
  }, [valueRefs, isUserLoggedIn, isRouteProtected]);

  return {
    isLoading: isAuthCheckLoading,
    isAuthenticated: isAuthCheckLoading ? undefined : isUserLoggedIn,
  };
};

export default useAuthRouteProtection;
