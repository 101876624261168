import { sum } from "lodash";

import permissionEnum from "./PermissionEnumHelper";

class UserPermissions {
  constructor({ userPermissionCodesSet }) {
    this.userPermissions = sum(
      [...userPermissionCodesSet].map(
        (userPermissionCode) => permissionEnum[userPermissionCode],
      ),
    );
  }

  /* Check one permission */
  hasPermission(permission) {
    const overlappingPermission = this.userPermissions & permission;
    return overlappingPermission === permission;
  }

  getHasPermissions({ requiredPermissions }) {
    return requiredPermissions.some((requiredPermission) => {
      return this.hasPermission(requiredPermission);
    });
  }

  /* Returns a list of all required permissions the user has. */
  getOverlappingPermissions({ requiredPermissions }) {
    return requiredPermissions.reduce(
      (overlappingPermissions, requiredPermission) => {
        const overlappingPermission = this.userPermissions & requiredPermission;

        if (overlappingPermission === requiredPermission) {
          return [...overlappingPermissions, overlappingPermission];
        }

        return overlappingPermissions;
      },
      [],
    );
  }

  /* Returns a list of all required permissions the user does not have. */
  getMissingPermissions({ requiredPermissions }) {
    const missingPermissionCombinations = requiredPermissions.reduce(
      (missingPermissions, permission) => {
        const missingPermission = permission & ~this.userPermissions;

        if (missingPermission) {
          return [...missingPermissions, missingPermission];
        }

        return missingPermissions;
      },
      [],
    );

    return missingPermissionCombinations;
  }
}

export default UserPermissions;
