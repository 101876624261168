import MentionStatusIcon from "./Prefix/MentionStatusIcon";
import MentionSymbol from "./Prefix/MentionSymbol";

export const mentionPrefixEnum = Object.freeze({
  statusIcon: "statusIcon",
  mentionSymbol: "mentionSymbol",
});

const MentionPrefix = ({ prefixType, agent }) => {
  switch (prefixType) {
    case mentionPrefixEnum.mentionSymbol: {
      return <MentionSymbol />;
    }

    case mentionPrefixEnum.statusIcon: {
      return <MentionStatusIcon agent={agent} />;
    }

    default: {
      return null;
    }
  }
};

export default MentionPrefix;
