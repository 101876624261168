import { useCallback, useEffect, useRef } from "react";

import { empty } from "@/utils/noopUtils";
import useValueRef from "./useValueRef";

/*
  This hook is useful when you need to debounce function calls in functional components.
*/
const useDebounce = ({ func = empty, delay = 1000 } = {}) => {
  const timer = useRef();
  const funcRef = useValueRef(func);

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction = useCallback(
    (...args) => {
      clearTimeout(timer.current);

      const newTimer = setTimeout(() => {
        funcRef.current(...args);
      }, delay);

      timer.current = newTimer;
    },
    [funcRef, delay],
  );

  return debouncedFunction;
};

export default useDebounce;
