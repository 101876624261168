export const rolePermissionEnum = Object.freeze({
  ACCESS_CRM: "ACCESS_CRM",
  ACCESS_EXTERNAL_API: "ACCESS_EXTERNAL_API",
  ACCESS_MESSAGE_BROADCASTS: "ACCESS_MESSAGE_BROADCASTS",
  ACCESS_PAYMENTS: "ACCESS_PAYMENTS",
  CONFIGURATION: "CONFIGURATION",
  MANAGE_CONVERSATION: "MANAGE_CONVERSATION",
  MANAGE_TAG: "MANAGE_TAG",
  MANAGE_VOICE_SETTINGS: "MANAGE_VOICE_SETTINGS",
  MONITOR_VOICE_CALL: "MONITOR_VOICE_CALL",
  PAYMENT_CONFIGURATION: "PAYMENT_CONFIGURATION",
  USE_TAG: "USE_TAG",
  VIEW_ALL_CONVERSATION: "VIEW_ALL_CONVERSATION",
  VIEW_FULL_PHONE_NUMBER: "VIEW_FULL_PHONE_NUMBER",
  VIEW_REPORTS: "VIEW_REPORTS",
  MANAGE_LIST: "MANAGE_LIST",
  EXPORT_CRM_DATA: "EXPORT_CRM_DATA",
  EXPORT_MESSAGE_BROADCASTS_DATA: "EXPORT_MESSAGE_BROADCASTS_DATA",
});

/*
  Custom client-only permissions to represent a certain state of backend. 
*/
export const clientPermissionEnum = Object.freeze({
  /* Chat requires a Messaging Provider in use. */
  ACCESS_CHAT_FEATURE: "ACCESS_CHAT_FEATURE",
  /* Voice feature requires isVoiceFeatureEnabled in feature plan. */
  ACCESS_VOICE_FEATURE: "ACCESS_VOICE_FEATURE",
});
