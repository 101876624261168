export const blockTypeEnum = Object.freeze({
  atomic: "atomic",
});

export const entityTypeEnum = Object.freeze({
  media: "media",
  mention: "mention",
  emv: "emv",
  link: "LINK",
});

export const bodyRawTypeEnum = Object.freeze({
  draftJs: "DRAFT_JS",
});

export const keyCommandEnum = Object.freeze({
  splitBlock: "split-block",
  uploadFile: "upload-file",
  sendMessage: "send-message",
  changeTab: "change-tab",
  moveFocusUp: "move-focus-up",
  moveFocusDown: "move-focus-down",
  canceledNativeEvent: "canceled-native-event",
  backspace: "backspace",
});

export const draftJsEntityTypeEnum = Object.freeze({
  IMMUTABLE: "IMMUTABLE",
  MUTABLE: "MUTABLE",
});

export const editorActionIconNamesEnum = Object.freeze({
  attachFile: "attachFile",
  cannedReplies: "cannedReplies",
  send: "send",
  addMessage: "addMessage",
  paymentLink: "paymentLink",
});

export const editorChangeTypeEnum = Object.freeze({
  removeRange: "remove-range",
});

export const mentionFormatEnum = Object.freeze({
  fullName: "FULL_NAME",
  firstName: "FIRST_NAME",
  lastName: "LAST_NAME",
  nickname: "NICKNAME",
});
