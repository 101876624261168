/*
  ⚠ IMPORTANT: voiceCallStatusEnum is used in 2 places: this repository and msg-click-to-call-browser-extension repository
  If you change any enum that is also exist in customVoiceCallStatusEnum, then you must also update that enum in msg-click-to-call-browser-extension
  If the enum is not used in customVoiceCallStatusEnum, then feel free to change it without worrying about them causing issue in the app
*/
export const voiceCallStatusEnum = Object.freeze({
  PENDING: "PENDING",
  ANSWERED: "ANSWERED",
  ANSWERED_BY_MACHINE: "ANSWERED_BY_MACHINE",
  ENDED: "ENDED",
  MOVED_TO_CHAT: "MOVED_TO_CHAT",
  SHORT_ABANDONED: "SHORT_ABANDONED",
  IVR_ABANDONED: "IVR_ABANDONED",
  ABANDONED: "ABANDONED",
  DECLINED: "DECLINED",
  MISSED: "MISSED",
  CANCELLED: "CANCELLED",
  TIMEOUT: "TIMEOUT",
  BUSY: "BUSY",
  DISCONNECTED: "DISCONNECTED",
  VOICEMAIL: "VOICEMAIL",
  FAILED: "FAILED",
  TRANSFER_ENDED: "TRANSFER_ENDED",
  TRANSFER_MOVED_TO_CHAT: "TRANSFER_MOVED_TO_CHAT",
  TRANSFER_DECLINED: "TRANSFER_DECLINED",
  TRANSFER_MISSED: "TRANSFER_MISSED",
  TRANSFER_TIMEOUT: "TRANSFER_TIMEOUT",
  TRANSFER_VOICEMAIL: "TRANSFER_VOICEMAIL",
  TRANSFER_FAILED: "TRANSFER_FAILED",
});

/*
  ⚠ IMPORTANT: ongoingCallStatuses is used in 2 places: this repository and msg-click-to-call-browser-extension repository
  This enum MUST always be the same as "ongoingCallStatuses" in msg-click-to-call-browser-extension sharedExternalVoiceCallStatusEnum.js
*/
export const ongoingVoiceCallStatuses = [
  voiceCallStatusEnum.PENDING,
  voiceCallStatusEnum.ANSWERED,
];
