import {
  camelCase,
  flatMap,
  has,
  isArray,
  isEmpty,
  mapValues,
  omitBy,
  pickBy,
} from "lodash";

import * as inboxViewPaneDefinition from "@/definitions/allowedInboxView/inboxViewPaneDefinition";
import allowedInboxViewEnum, {
  allowedInboxViewOrderByEnum,
  allowedInboxViewSectionEnum,
} from "@/enums/allowedInboxViewEnum";
import { conversationStatusEnum } from "@/enums/conversation";
import { dataObjectTypenameEnum } from "@/enums/typename";
import { snakeCaseToUpperCase } from "./commonUtils";

export const inboxPageViewSectionObjectMapping = {
  [allowedInboxViewSectionEnum.group]: {
    dataObjectTypename: dataObjectTypenameEnum.groupObject,
    fragment: inboxViewPaneDefinition.GROUP_INBOX_VIEW_PANE_FRAGMENT,
  },
  [allowedInboxViewSectionEnum.agent]: {
    dataObjectTypename: dataObjectTypenameEnum.agentObject,
    fragment: inboxViewPaneDefinition.AGENT_INBOX_VIEW_PANE_FRAGMENT,
  },
  [allowedInboxViewSectionEnum.conversationTags]: {
    dataObjectTypename: dataObjectTypenameEnum.contentTypeAllowedTagObject,
    fragment:
      inboxViewPaneDefinition.CONTENT_TYPE_ALLOWED_TAG_INBOX_VIEW_PANE_FRAGMENT,
  },
  [allowedInboxViewSectionEnum.contactTags]: {
    dataObjectTypename: dataObjectTypenameEnum.contentTypeAllowedTagObject,
    fragment:
      inboxViewPaneDefinition.CONTENT_TYPE_ALLOWED_TAG_INBOX_VIEW_PANE_FRAGMENT,
  },
};

/*
  Core Inbox page route queries that should always be present.
  TODO: Rename to inboxPageCoreRouteQueryNames in MSG-2886.
*/
const coreInboxPageRouteQueryNamesOrdering = [
  "inboxViewSection",
  "inboxViewId",
  "status",
  "orderBy",
  "params",
];

/* Optional Inbox page route queries that only appears occasionally. */
const optionalInboxPageRouteQueryNames = ["eventId", "selectedContactId"];

const defaultInboxPageRouterQuery = {
  inboxViewSection: allowedInboxViewSectionEnum.special,
  inboxViewId: allowedInboxViewEnum.me,
  status: conversationStatusEnum.active,
  orderBy: allowedInboxViewOrderByEnum.newest,
};

export const getCurrentConversationIdFromRouter = ({
  params = [],
  pathname = "",
  strict = false,
}) => {
  const isInboxPage = pathname.startsWith("/inbox");
  const conversationId = params[0];
  const isStrictValidationFailed = strict && isNaN(conversationId);

  if (!isInboxPage || isStrictValidationFailed) return;

  return conversationId;
};

/* Checks if basic inbox router query values are present and if they exist.. */
export const validateInboxRouterQueryValues = ({
  routerQuery = {},
  client = null,
}) => {
  const { inboxViewSection, inboxViewId, status, orderBy } = routerQuery;

  if (!inboxViewSection || !inboxViewId || !status || !orderBy) return false;

  if (!allowedInboxViewSectionEnum[inboxViewSection]) return false;

  /* NOTE: allowedInboxViewSectionEnum.special is not dynamic for MVP */
  if (
    inboxViewSection === allowedInboxViewSectionEnum.special &&
    !allowedInboxViewEnum[inboxViewId]
  )
    return false;

  /*
    Check if a dynamic inbox view (e.g. a tag) exist on the views pane
    since they are not returned from backend if they are not used in 
    any conversation.
  */
  if (inboxViewSection !== allowedInboxViewSectionEnum.special && client) {
    const allowedInboxSectionObjectRef = client.cache.identify({
      id: inboxViewId,
      __typename:
        inboxPageViewSectionObjectMapping[inboxViewSection].dataObjectTypename,
    });

    const cachedAllowedInboxSectionObject = client.readFragment({
      id: allowedInboxSectionObjectRef,
      fragment: inboxPageViewSectionObjectMapping[inboxViewSection].fragment,
    });

    return Boolean(cachedAllowedInboxSectionObject);
  }

  const allowedInboxViewOrderByEnumValues = Object.values(
    allowedInboxViewOrderByEnum,
  );

  if (
    !conversationStatusEnum[status] ||
    !allowedInboxViewOrderByEnumValues.includes(orderBy)
  )
    return false;

  return true;
};

export const getCurrentInboxViewId = ({ inboxViewSection, inboxViewId }) => {
  return inboxViewSection !== allowedInboxViewSectionEnum.special
    ? inboxViewId
    : snakeCaseToUpperCase(inboxViewId);
};

/*
  Convenience method to construct the allowedInboxViewInput
  filter object from the router to use for queries/building cache key.

  Returns an object, spread if necessary.
*/
export const parseAllowedInboxViewInputFromRouter = ({
  inboxViewSection,
  inboxViewId,
}) => {
  const parsedInboxViewId = getCurrentInboxViewId({
    inboxViewSection,
    inboxViewId,
  });

  if (parsedInboxViewId === allowedInboxViewEnum.priority)
    return { isPriority: true };

  const isInboxViewSectionTags =
    inboxViewSection === allowedInboxViewSectionEnum.conversationTags ||
    inboxViewSection === allowedInboxViewSectionEnum.contactTags;

  return {
    allowedInboxViewInput: {
      [inboxViewSection]: isInboxViewSectionTags
        ? [parsedInboxViewId]
        : parsedInboxViewId,
    },
  };
};

/* 
  Evaluates if the current inbox view is where the conversation
  should be and if we should apply our custom sorting to it.
*/
export const validateAllowedInboxView = ({
  allowedInboxView,
  conversationStatus,
  routerQuery = {},
  parentConversationId = null,
}) => {
  const { inboxViewSection, inboxViewId, status, orderBy } = routerQuery;

  const allowedInboxViewSectionValue = allowedInboxView?.[inboxViewSection];
  const currentInboxViewId = getCurrentInboxViewId({
    inboxViewSection,
    inboxViewId,
  });

  const isActiveConversation =
    conversationStatus !== conversationStatusEnum.resolved;

  const doesResolvedConversationHaveParentConversation =
    !isActiveConversation && parentConversationId !== null;

  const isCorrectViewForStatus = isActiveConversation
    ? status === camelCase(conversationStatusEnum.active)
    : status === camelCase(conversationStatusEnum.resolved);

  const isAllowedInboxView = isArray(allowedInboxViewSectionValue)
    ? allowedInboxViewSectionValue.includes(currentInboxViewId)
    : allowedInboxViewSectionValue === currentInboxViewId;

  /* -1 means it should be placed at the bottom of the list, 0 means it should be somewhere on top (default). */
  const direction =
    orderBy === allowedInboxViewOrderByEnum.oldest ||
    orderBy === allowedInboxViewOrderByEnum.waitingLongest
      ? -1
      : 0;

  return {
    direction,
    isAllowedInboxView,
    isCorrectViewForStatus,

    /* Convenience property for most use cases. */
    isInboxViewStillValid:
      isAllowedInboxView &&
      (isCorrectViewForStatus ||
        doesResolvedConversationHaveParentConversation),
  };
};

/*
  Assumes queryValuesToUpdate property values are neither objects nor arrays.
  If next/router is not used to push the URL (e.g. when opening a new tab),
  set returnAsUrlString to true.
*/
export const constructInboxPageRouterQueryUrl = ({
  isDefault,
  returnAsUrlString = false,
  router = {},
  queryValuesToUpdate = {},

  /* Array of keys whose values shouldn't be transformed. */
  preserveRawValueFor = ["orderBy", "selectedContactId", "eventId"],
} = {}) => {
  const { query: routerQuery = {}, pathname = "" } = router;
  const isInboxPage = pathname.startsWith("/inbox");
  const newQuery = isDefault
    ? defaultInboxPageRouterQuery
    : {
        ...defaultInboxPageRouterQuery,
        ...(isInboxPage && routerQuery),
        ...queryValuesToUpdate,
      };

  const isSpecialInboxViewSection =
    newQuery.inboxViewSection === allowedInboxViewSectionEnum.special;

  const filteredRouteQueryMapping = omitBy(newQuery, (_, queryName) => {
    /* Exclude optional query value if they are not in queryValuesToUpdate. */
    const shouldExcludeOptionalQueryValue =
      optionalInboxPageRouteQueryNames.includes(queryName);
    const optionalQueryValueNotUpdated = !has(queryValuesToUpdate, queryName);

    return shouldExcludeOptionalQueryValue && optionalQueryValueNotUpdated;
  });

  const parsedNewRouteQueryMapping = mapValues(
    filteredRouteQueryMapping,
    (queryValue, queryName) => {
      if (
        preserveRawValueFor.includes(queryName) ||
        (!isSpecialInboxViewSection && queryName !== "status")
      ) {
        return queryValue;
      }

      if (isArray(queryValue)) {
        return queryValue.map((val) => camelCase(val));
      }
      return camelCase(queryValue);
    },
  );

  if (!returnAsUrlString) return parsedNewRouteQueryMapping;

  const optionalRouteQueryMapping = pickBy(
    parsedNewRouteQueryMapping,
    (queryValue, queryName) =>
      optionalInboxPageRouteQueryNames.includes(queryName),
  );

  /* TODO: Revise in MSG-2886. */
  const orderedCoreRouteQueryValueArray =
    coreInboxPageRouteQueryNamesOrdering.map(
      (queryName) => parsedNewRouteQueryMapping[queryName],
    );

  const urlSearchParamString = !isEmpty(optionalRouteQueryMapping)
    ? "?" + new URLSearchParams(optionalRouteQueryMapping).toString()
    : "";

  return (
    "/inbox/" +
    flatMap(orderedCoreRouteQueryValueArray, (val) => val).join("/") +
    urlSearchParamString
  );
};

/* The most common router operation in inbox */
export const goToInboxPageWithRouter = ({
  isDefault = false,
  routerOperation = "push",
  router = {},
  queryValuesToUpdate = {},
}) => {
  const query = queryValuesToUpdate
    ? constructInboxPageRouterQueryUrl({
        isDefault,
        router,
        queryValuesToUpdate,
      })
    : {};

  router?.[routerOperation]?.(
    {
      pathname:
        "/inbox/[inboxViewSection]/[inboxViewId]/[status]/[orderBy]/[[...params]]",
      query,
    },
    undefined,
    { shallow: true },
  );
};
