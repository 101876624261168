import { useRef } from "react";

/*
  This effect is useful when you want to access the updated value of a variable
  without adding it as a dependency in another hook in functional components
*/
const useValueRef = (value) => {
  const valueRef = useRef();
  valueRef.current = value;

  return valueRef;
};

export default useValueRef;
