import { gql } from "@apollo/client";

import {
  allAverageVoiceCallTimeReportDefinition,
  allTotalVoiceCallTimeReportDefinition,
  totalAnsweredVoiceCallCountReportDefinition,
  totalMissedVoiceCallCountReportDefinition,
  voiceReportMetricsPerPeriodVarDefinition,
} from "@/definitions/reporting/voiceReportingDefinitions";

/* Answered call count queries */

export const GET_TEAM_TOTAL_ANSWERED_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamAllAnsweredVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${totalAnsweredVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_TOTAL_ANSWERED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAllAnsweredVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${totalAnsweredVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_TOTAL_ANSWERED_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentAllAnsweredVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${totalAnsweredVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_TOTAL_ANSWERED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentAllAnsweredVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${totalAnsweredVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_TOTAL_ANSWERED_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDAllAnsweredVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition}
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${totalAnsweredVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Missed call count queries */

export const GET_TEAM_TOTAL_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamTotalMissedVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${totalMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_TOTAL_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupTotalMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${totalMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_TOTAL_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentTotalMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${totalMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_TOTAL_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentTotalMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${totalMissedVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_TOTAL_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDTotalMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${totalMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Total call time queries */

export const GET_TEAM_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamAllTotalVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${allTotalVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

/* Average call time queries */

export const GET_TEAM_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamAllAverageVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${allAverageVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;
