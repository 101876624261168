const allowedInboxViewEnum = Object.freeze({
  me: "ME",
  all: "ALL",
  new: "NEW",
  priority: "PRIORITY",
  mention: "MENTION",
  unattended: "UNATTENDED",
});

export const allowedInboxViewLabel = Object.freeze({
  [allowedInboxViewEnum.me]: "You",
  [allowedInboxViewEnum.all]: "All",
  [allowedInboxViewEnum.new]: "Unassigned",
  [allowedInboxViewEnum.priority]: "Priority",
  [allowedInboxViewEnum.mention]: "Mentions",
  [allowedInboxViewEnum.unattended]: "Unattended",
});

export const allowedInboxViewSectionEnum = Object.freeze({
  special: "special",
  pinnedViews: "pinnedViews",
  agent: "agent",
  group: "group",
  contactTags: "contactTags",
  conversationTags: "conversationTags",
});

export const allowedInboxViewOrderByEnum = Object.freeze({
  oldest: "mostRecentConversationEventCreated",
  newest: "-mostRecentConversationEventCreated",
  waitingLongest: "longestWaitingConversation",
  priorityFirst: "-isPriority",
});

export const conversationCardSizeSelectorValueEnum = Object.freeze({
  compact: "Compact",
  detail: "Detail",
});

export default allowedInboxViewEnum;
