import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mentionSymbol: {
    color: theme.palette.grey[1800],
  },
}));

const MentionSymbol = () => {
  const classes = useStyles();

  return <span className={classes.mentionSymbol}>@</span>;
};

export default MentionSymbol;
