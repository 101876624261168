import { gql } from "@apollo/client";

import {
  outboundAverageVoiceCallTimeReportDefinition,
  outboundCancelledVoiceCallCountReportDefinition,
  outboundMissedVoiceCallCountReportDefinition,
  outboundTotalVoiceCallTimeReportDefinition,
  outboundVoiceCallCountReportDefinition,
  voiceReportMetricsPerPeriodVarDefinition,
} from "@/definitions/reporting/voiceReportingDefinitions";

/* Total call count queries */

export const GET_TEAM_OUTBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamOutboundVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${outboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_OUTBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupOutboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${outboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_OUTBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentOutboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${outboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_OUTBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentOutboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${outboundVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_OUTBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDOutboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${outboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Total call time queries */

export const GET_TEAM_OUTBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamOutboundTotalVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${outboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_OUTBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupOutboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${outboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_OUTBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentOutboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${outboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_OUTBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentOutboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${outboundTotalVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_OUTBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDOutboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${outboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

/* Average call time queries */

export const GET_TEAM_OUTBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamOutboundAverageVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${outboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_OUTBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupOutboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${outboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_OUTBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentOutboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${outboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_OUTBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentOutboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${outboundAverageVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_OUTBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDOutboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${outboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

/* Missed call count queries */

export const GET_TEAM_OUTBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamOutboundMissedVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${outboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_OUTBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupOutboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${outboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_OUTBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentOutboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${outboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_OUTBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentOutboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${outboundMissedVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_OUTBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDOutboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${outboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Cancelled call count queries */

export const GET_TEAM_OUTBOUND_CANCELLED_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamOutboundCancelledVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${outboundCancelledVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_OUTBOUND_CANCELLED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupOutboundCancelledVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${outboundCancelledVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_OUTBOUND_CANCELLED_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentOutboundCancelledVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${outboundCancelledVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_OUTBOUND_CANCELLED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentOutboundCancelledVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${outboundCancelledVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_OUTBOUND_CANCELLED_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDOutboundCancelledVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${outboundCancelledVoiceCallCountReportDefinition}
      }
    }
  }
`;
