import { useEffect, useRef } from "react";

const useIsFirstRenderRef = () => {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      isFirstRenderRef.current = false;
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isFirstRenderRef;
};

export default useIsFirstRenderRef;
