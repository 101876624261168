export const filterLookupEnum = Object.freeze({
  EXACT: "EXACT",
  IEXACT: "IEXACT" /* Case-insensitive */,
  CONTAINS: "CONTAINS",
  ICONTAINS: "ICONTAINS" /* Case-insensitive */,
  IN: "IN",
  GT: "GT",
  GTE: "GTE",
  LT: "LT",
  LTE: "LTE",
  STARTSWITH: "STARTSWITH",
  ISTARTSWITH: "ISTARTSWITH" /* Case-insensitive */,
  ENDSWITH: "ENDSWITH",
  IENDSWITH: "IENDSWITH" /* Case-insensitive */,
  RANGE: "RANGE",
  ISNULL: "ISNULL",
  REGEX: "REGEX",
  IREGEX: "IREGEX" /* Case-insensitive */,
  DATE: "DATE",
  YEAR: "YEAR",
  ISO_YEAR: "ISO_YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
  WEEK: "WEEK",
  WEEK_DAY: "WEEK_DAY",
  ISO_WEEK_DAY: "ISO_WEEK_DAY",
  QUARTER: "QUARTER",
  TIME: "TIME",
  HOUR: "HOUR",
  MINUTE: "MINUTE",
  SECOND: "SECOND",
  CONTAINED_BY: "CONTAINED_BY",
  HAS_KEY: "HAS_KEY",
  HAS_KEYS: "HAS_KEYS",
  HAS_ANY_KEYS: "HAS_ANY_KEYS",
  JSON_KEY: "JSON_KEY",
});

export const filterOperatorEnum = Object.freeze({
  AND: "AND",
  OR: "OR",
});

export const sortByEnum = Object.freeze({
  ASC: "ASC",
  DESC: "DESC",
});
