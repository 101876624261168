import { gql } from "@apollo/client";

import {
  inboundAverageVoiceCallTimeReportDefinition,
  inboundMissedVoiceCallCountReportDefinition,
  inboundTotalVoiceCallTimeReportDefinition,
  inboundVoiceCallCountReportDefinition,
  inboundVoicemailVoiceCallCountReportDefinition,
  voiceReportMetricsPerPeriodVarDefinition,
} from "@/definitions/reporting/voiceReportingDefinitions";

/* Total call count queries */

export const GET_TEAM_INBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamInboundVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${inboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_INBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupInboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${inboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_INBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentInboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${inboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_INBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentInboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${inboundVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_INBOUND_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDInboundVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${inboundVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Total call time queries */

export const GET_TEAM_INBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamInboundTotalVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${inboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_INBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupInboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${inboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_INBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentInboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${inboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_INBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentInboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${inboundTotalVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_INBOUND_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDInboundTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${inboundTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

/* Average call time queries */

export const GET_TEAM_INBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamInboundAverageVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${inboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_INBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupInboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${inboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_INBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentInboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${inboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_INBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentInboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${inboundAverageVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_INBOUND_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDInboundAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${inboundAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

/* Missed call count queries */

export const GET_TEAM_INBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamInboundMissedVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${inboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_INBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupmInboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${inboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_INBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentmInboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${inboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_INBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentmInboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${inboundMissedVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_INBOUND_MISSED_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDmInboundMissedVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${inboundMissedVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Voicemail count queries */

export const GET_TEAM_INBOUND_VOICEMAIL_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamInboundVoicemailVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${inboundVoicemailVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_INBOUND_VOICEMAIL_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupInboundVoicemailVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${inboundVoicemailVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_INBOUND_VOICEMAIL_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentInboundVoicemailVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${inboundVoicemailVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_INBOUND_VOICEMAIL_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentInboundVoicemailVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${inboundVoicemailVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_INBOUND_VOICEMAIL_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDInboundVoicemailVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${inboundVoicemailVoiceCallCountReportDefinition}
      }
    }
  }
`;
