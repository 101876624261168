import { gql } from "@apollo/client";

import * as inboxPagePaymentSessionDefinition from "@/definitions/paymentSessionDefinitions/inboxPagePaymentSessionDefinition";
import { videoSessionScreenshotDefinition } from "@/definitions/videoCallDefinitions";
import * as sharedConversationDefinitions from "../sharedConversationDefinitions";

const voiceProviderContactDefinition = `{
  id
  contact ${sharedConversationDefinitions.contactDefinition}
}`;

export const recordingDefinition = `{
  id
  startDatetime
  duration
  isTranscriptionCompleted
  media {
    uuid
    mediaFileUrl
    filename
  }
  voiceConversation {
    id
    voiceProviderContact ${voiceProviderContactDefinition}
    initiatorVoiceLeg {
      id
      agent ${sharedConversationDefinitions.agentDefinition}
      voiceProviderContact ${voiceProviderContactDefinition}
    }
  }
}`;

export const voicemailDefinition = `{
  id
  duration
  media {
    uuid
    mediaFileUrl
  }
}`;

export const conversationDispositionValueDefinition = `{
  id
  value
  conversationDispositionField {
    id
    name
  }
  conversationDispositionChoice {
    id
    label
  }
}`;

const voiceConversationDefinition = `{
  id
  initiatorVoiceLeg {
    id
    voiceProviderAccount {
      id
      name
    }
  }
}`;

const conversationDefinition = `{
  id
  lastVoiceConversation ${voiceConversationDefinition}
  conversationDispositionValues ${conversationDispositionValueDefinition}
  conversationSummary ${sharedConversationDefinitions.conversationSummaryDefinition}
  group ${sharedConversationDefinitions.groupDefinition}
  conversationTags ${sharedConversationDefinitions.conversationTagsDefinition}
  messagingProviderAccount {
    id
    name
    messagingProvider {
      id
      name
    }
  }
}`;

/* Used when the conversation is the target for the event */
const targetConversationDefinition = `{
  id
  contact {
    id
    phoneNumber
  }
  messagingProviderAccount {
    id
    messagingProvider {
      id
      messagingProviderLabel
    }
  }
}`;

const conversationMessageButtonDefinition = `{
  id
  buttonActionType
  buttonText
  buttonUrl
}`;

export const conversationMessageDefinition = `{
    id
    clientReference
    status
    direction
    messageType
    messageContentType
    failedErrorCode
    failedErrorMessage
    buttons ${conversationMessageButtonDefinition}
    textConversationMessage ${sharedConversationDefinitions.textConversationMessageDefinition}
    mediaConversationMessage ${sharedConversationDefinitions.mediaConversationMessageDefinition}
    locationConversationMessage ${sharedConversationDefinitions.locationConversationMessageDefinition}
    whatsappHsmConversationMessage ${sharedConversationDefinitions.whatsappHsmConversationMessageDefinition} 
    senderAgent ${sharedConversationDefinitions.agentDefinition}
    senderMessagingProviderContact ${sharedConversationDefinitions.messagingProviderContactDefinition} 
    senderBotUser ${sharedConversationDefinitions.senderBotUserDefinition}
    contextReplyReference {
      id
      direction
      messageType
      messageContentType
      textConversationMessage ${sharedConversationDefinitions.textConversationMessageDefinition}
      mediaConversationMessage ${sharedConversationDefinitions.mediaConversationMessageDefinition}
      locationConversationMessage ${sharedConversationDefinitions.locationConversationMessageDefinition}
      whatsappHsmConversationMessage ${sharedConversationDefinitions.whatsappHsmConversationMessageDefinition} 
      senderAgent ${sharedConversationDefinitions.agentDefinition}
      senderMessagingProviderContact ${sharedConversationDefinitions.messagingProviderContactDefinition} 
      senderBotUser ${sharedConversationDefinitions.senderBotUserDefinition}
      conversation {
        id
      }
      events {
        id
        created
      }
    }
}`;

export const conversationNoteDefinition = `{
    id
    clientReference
    textConversationNote ${sharedConversationDefinitions.textConversationNoteDefinition}
    mediaConversationNote ${sharedConversationDefinitions.mediaConversationNoteDefinition}
    locationConversationNote ${sharedConversationDefinitions.locationConversationNoteDefinition}
    noteWriter ${sharedConversationDefinitions.agentDefinition}
    mentions {
      id
      targetAgent ${sharedConversationDefinitions.mentionAgentDefinition}
    }
}`;

export const voiceIVRInteractionObjectDefinition = `{
  id
  interactionText
  created
  voiceIvrSession {
    id
    created
  }
}`;

/*
  Important: Any new paymentConfigurationFieldSnapshot field must be added to sharedPaymentConfigurationFieldDefinition instead of below.
  This is required to prevent mismatch of object equality check in ResendPaymentRequestButton.js
*/
export const paymentSessionDefinition = `{
  id
  amount
  created
  completedAt
  expiryDatetime
  paymentSessionStatus: status
  paymentMethodIdentifier
  currency {
    id
    alphaCode
  }

  paymentConfiguration {
    id
    name
  }

  paymentSessionConfigurationValues {
    id
    valueText
    valueNumber
    valueFile {
      uuid
      contentType
      filename
      mediaFileUrl
    }
    
    paymentConfigurationField {
      id
    }
    
    paymentConfigurationFieldSnapshot {
      ${inboxPagePaymentSessionDefinition.sharedPaymentConfigurationFieldDefinition}
    }
  }
}`;

export const videoSessionArchiveDefinition = `{
  id
  contact {
    id
    displayName
  }
  
  videoSession {
    id
    startDatetime
    endDatetime
  }
  
  videoProviderSessionArchive {
    id
    archiveUrl
  }
}`;

export const landingPageDocumentVisitLogDefinition = `{
  id
  created
  totalDwellTime

  landingPageDocumentVersion {
    id
    versionNumber
    landingPageDocument {
      id
      name
    }
  }

  shortLinkVisitLog {
    id
    userAgent
    device
    country
    cityName
    ipAddress
  }
}`;

/*
  If you add any properties to this definition or any definition it references you must:
    - Reflect the changes in conversationEventSubscriptionDefinition
    - Make sure you make the needed additions to the object returned by "prepareInitialConversationEventObject"
      in the chatMessageEditorUtils utils so the temporary object we make has all the needed properties that exist here
    
  If these things are not done, apollo will fail to resolve the missing fields when we use the definition in a fragment
  and will trigger a refetch as a result while writing to the cache.
*/
export const conversationEventDefinition = `{
    id
    created
    eventType
    actor {
        ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
        ... on BotUserObject  ${sharedConversationDefinitions.senderBotUserDefinition}
        ... on ContactObject ${sharedConversationDefinitions.contactDefinition}
        ... on VoiceProviderContactObject ${sharedConversationDefinitions.voiceProviderContactDefinition}
        ... on MessagingProviderContactObject ${sharedConversationDefinitions.messagingProviderContactDefinition} 
    }

    actionObject {
        ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
        ... on ConversationObject ${conversationDefinition}
        ... on ContentTypeAllowedTagObject ${sharedConversationDefinitions.contentTypeAllowedTagDefinition}
        ... on ConversationMessageObject ${conversationMessageDefinition}
        ... on ConversationNoteObject ${conversationNoteDefinition}
        ... on ConversationSummaryObject ${sharedConversationDefinitions.conversationSummaryDefinition}
        ... on PaymentSessionObject ${paymentSessionDefinition}
        ... on RecordingObject ${recordingDefinition}
        ... on VideoSessionScreenshotObject ${videoSessionScreenshotDefinition}
        ... on VoiceProviderContactObject ${sharedConversationDefinitions.voiceProviderContactDefinition}
        ... on VoiceIVRInteractionObject ${voiceIVRInteractionObjectDefinition}
        ... on VoicemailObject ${voicemailDefinition}
        ... on VoiceConversationObject ${voiceConversationDefinition}
        ... on VideoSessionArchiveObject ${videoSessionArchiveDefinition}
        ... on LandingPageDocumentVisitLogObject ${landingPageDocumentVisitLogDefinition}
    }
    
    context {
      ... on AgentAssignmentObject ${sharedConversationDefinitions.agentAssignmentContextDefinition}
    }

    target {
        ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
        ... on GroupObject ${sharedConversationDefinitions.groupDefinition}
        ... on ConversationObject ${targetConversationDefinition}
        ... on VoiceProviderContactObject ${sharedConversationDefinitions.voiceProviderContactDefinition}
        ... on VoiceProviderAccountObject ${sharedConversationDefinitions.voiceProviderAccountDefinition}
        ... on RecordingObject ${recordingDefinition}
        ... on VoiceConversationObject ${voiceConversationDefinition}
        ... on ConversationNoteObject ${conversationNoteDefinition}
      }

    conversation ${conversationDefinition}
}`;

export const NEW_CONVERSATION_EVENT_FRAGMENT = gql`
  fragment NewConversationEvent on RelatedEventObject ${conversationEventDefinition}
`;

export const NEW_CONVERSATION_MESSAGE_FRAGMENT = gql`
  fragment NewConversationMessage on ConversationMessageObject ${conversationMessageDefinition}
`;

export const NEW_CONVERSATION_NOTE_FRAGMENT = gql`
  fragment NewConversationNote on ConversationNoteObject ${conversationNoteDefinition}
`;

export const CONVERSATION_MESSAGE_ERROR_FRAGMENT = gql`
  fragment ConversationMessageError on ConversationMessageObject {
    status
    failedErrorCode
    failedErrorMessage
  }
`;
