export const editorTabLabel = Object.freeze({
  reply: "Reply",
  notes: "Private Note",
  awayMessage: "Away Message",
});

export const chatEditorPlaceholderEnum = Object.freeze({
  reply:
    "Start with a '/' to select a Canned Response; Shift + Enter to add a new line",
  notes: "Private notes are available to you and your team",
  initiateConversation: "Use /h to send HSM and start a conversation",
  initiateConversationWithoutHsm: "Send a message to start a conversation",
  outsideAllowedWindow:
    "Start with /h to select a template message to restart conversation",
  noMessageAction: "You have no permission to manage this conversation",
});
