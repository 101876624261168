import {
  findPhoneNumbersInText,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js/max";
import { flow, isEmpty, join, take } from "lodash";

/* Eg. Match "0321" in any of ["+628527500321", "+62852750 0321", "+628527500 321"] */
const LAST_FOUR_DIGITS_REGEX = /(\d(?:(?:\s|-)*\d){3})$/;

/* Eg. Match the whole number in any of ["+62852750****", "+62852750* ***", "+62852750 ****"] */
const IS_MASKED_PHONE_NUMBER_REGEX = /^[0-9+][0-9\s-]*\*\s*\*\s*\*\s*\*\s*$/;

/* Function that masks the last four digits of a phone number with asterisks while preserving any optional separator (whitespace or hyphen) */
const getMaskedPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  return phoneNumber.replace(LAST_FOUR_DIGITS_REGEX, (match) =>
    match.replace(/\d/g, "*"),
  );
};

/*
    findPhoneNumbersInText only returns valid phone numbers that has country code and + sign
    ✅ ["+628991234500", "+62899 1234 500 - Andy"]
    ❌ ["628991234500", "62899"]
*/
export const checkIsPhoneNumberInDisplayName = (displayName) => {
  const phoneNumbersInText = findPhoneNumbersInText(displayName || "");
  return !isEmpty(phoneNumbersInText);
};

const getContactNameWithPhoneNumbersInText = ({
  displayName,
  shouldMaskPhoneNumber,
}) => {
  const phoneNumbersInText = findPhoneNumbersInText(displayName || "-");

  return phoneNumbersInText.reduce((acc, phoneNumberInTextObject) => {
    const {
      number: phoneNumberObject,
      startsAt,
      endsAt,
    } = phoneNumberInTextObject;

    const formattedContactPhoneNumber = phoneNumberObject.formatInternational();

    const parsedPhoneNumber = shouldMaskPhoneNumber
      ? getMaskedPhoneNumber(formattedContactPhoneNumber)
      : formattedContactPhoneNumber;

    return acc.slice(0, startsAt) + parsedPhoneNumber + acc.slice(endsAt);
  }, displayName);
};

export const getFormattedPhoneNumber = ({
  displayName,
  shouldMaskPhoneNumber,
}) => {
  const validatedPhoneNumber = (() => {
    const phoneNumberParsedToString = String(displayName);
    const phoneNumberWithPlusSign = `+${displayName}`;

    if (isValidPhoneNumber(phoneNumberParsedToString))
      return phoneNumberParsedToString;

    if (isValidPhoneNumber(phoneNumberWithPlusSign))
      return phoneNumberWithPlusSign;

    return null;
  })();

  if (validatedPhoneNumber) {
    const parsedPhoneNumber = parsePhoneNumber(validatedPhoneNumber);
    const formattedContactPhoneNumber = parsedPhoneNumber.formatInternational();
    return shouldMaskPhoneNumber
      ? getMaskedPhoneNumber(formattedContactPhoneNumber)
      : formattedContactPhoneNumber;
  }

  const hasPhoneNumberInDisplayName =
    checkIsPhoneNumberInDisplayName(displayName);

  /* If phone number is found in the displayName eg "+628991234500 - Andy", ensure it is masked accordingly */
  if (hasPhoneNumberInDisplayName) {
    return getContactNameWithPhoneNumbersInText({
      displayName,
      shouldMaskPhoneNumber,
    });
  }

  /* If displayName is not a phone number, return directly without any modification */
  return displayName;
};

/* Eg. Match last n-digits eg. 2 digits = "50" in any of ["+62852750****", "+62852750* ***", "+62852750 ****"] */
const getLastNDigitsBeforeAsterisk = (digits) => {
  const regexString = `\\d\\s?\\d{${digits - 1}}(?=\\D*\\*\\s*)`;
  return new RegExp(regexString);
};

export const getNameInitials = (value, length = 2) => {
  if (!value) return "";

  /* Return last 2 digits of phoneNumber for contact without fullName */
  if (isValidPhoneNumber(value)) return value.slice(-2);

  /* Masked phone number is a phone number that contains 4 trailing asterisk w/o optional space */
  const isMaskedPhoneNumber = IS_MASKED_PHONE_NUMBER_REGEX.test(value);

  if (isMaskedPhoneNumber) {
    const LAST_N_DIGITS_BEFORE_ASTERISK_REGEX =
      getLastNDigitsBeforeAsterisk(length);

    const lastNDigits = value.match(LAST_N_DIGITS_BEFORE_ASTERISK_REGEX)[0];

    return lastNDigits;
  }

  /* Ensure leading/trailing/in-between whitespaces are removed */
  const namesArray = value
    .trim()
    .split(" ")
    .filter((name) => name !== "");

  return flow(
    (namesArray) => namesArray.map((name) => name[0].toUpperCase()),
    (namesInitials) => take(namesInitials, length),
    (namesInitials) => join(namesInitials, ""),
  )(namesArray);
};

export const getContactInitials = ({ displayName, shouldMaskPhoneNumber }) => {
  const formattedContactDisplayName = getFormattedPhoneNumber({
    displayName,
    shouldMaskPhoneNumber,
  });

  return getNameInitials(formattedContactDisplayName);
};
