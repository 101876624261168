import { useEffect, useState } from "react";

/*
  This hook returns a boolean indicating whether a subscribed key is being held or not.
  Reference: https://stackoverflow.com/a/66913595/14101116
*/
const useKeyHeldListener = ({ targetKey, elementRef }) => {
  const [isKeyHeld, setIsKeyHeld] = useState(false);

  useEffect(() => {
    if (!targetKey) return;

    const element = elementRef?.current || window;

    const keyDownHandler = ({ key }) => {
      if (key === targetKey) {
        setIsKeyHeld(true);
      }
    };

    const keyUpHandler = ({ key }) => {
      if (key === targetKey) {
        setIsKeyHeld(false);
      }
    };

    element.addEventListener("keydown", keyDownHandler);
    element.addEventListener("keyup", keyUpHandler);

    return () => {
      element.removeEventListener("keydown", keyDownHandler);
      element.removeEventListener("keyup", keyUpHandler);
    };
  }, [elementRef, targetKey]);

  return isKeyHeld;
};

export default useKeyHeldListener;
