export const voiceLegTypeEnum = Object.freeze({
  VOICE_CALL: "VOICE_CALL",
  SELF_JOIN: "SELF_JOIN",
  CALL_MONITOR: "CALL_MONITOR",
});

export const joinVoiceLegTypes = [
  voiceLegTypeEnum.VOICE_CALL,
  voiceLegTypeEnum.SELF_JOIN,
];
