import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";

export const videoSessionDatetimeDefinition = `{
  id
  startDatetime
  endDatetime
}`;

export const generateVideoSessionTokenMutationDefinition = `{
  accountId
  sessionId
  token
  expiresAt
}`;

export const videoSessionDefinition = `{
  id
  contact {
    id
    fullName
    displayName
  }
  
  conversation {
    id
    assignee {
      id
    }
  }
  
  videoProviderSession {
    id
    videoProviderAccount {
      id
      shouldAutoArchive
    }
  }
}`;

export const videoSessionScreenshotDefinition = `{
  id
  media ${sharedConversationDefinitions.mediaDefinition}
}`;
