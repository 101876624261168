import StatusIcon from "@/components/StandardAppBar/UserAvatar/StatusIcon";

const MentionStatusIcon = ({ agent }) => {
  const { agentCurrentStatus = {}, isActive } = agent;

  if (!isActive) return null;

  return (
    <span>
      <StatusIcon
        isSmall
        canChat={agentCurrentStatus.canChat}
        canVideoCall={agentCurrentStatus.canVideoCall}
        canVoiceCall={agentCurrentStatus.canVoiceCall}
        systemStatus={agentCurrentStatus.status?.systemStatus}
      />
    </span>
  );
};

export default MentionStatusIcon;
