export const conversationStatusEnum = Object.freeze({
  resolved: "RESOLVED",
  pending: "PENDING",
  active: "ACTIVE",
});

export const conversationStatusInEnum = Object.freeze({
  active: [conversationStatusEnum.active, conversationStatusEnum.pending],
  resolved: [conversationStatusEnum.resolved],
});
