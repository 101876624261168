import moment from "moment";

import { customDateRangeEnum } from "@/enums/datetimeEnum";

export const dateRangeOptions = [
  { label: "Last 24 hours", value: customDateRangeEnum.last24Hours },
  { label: "Last week", value: customDateRangeEnum.lastWeek },
  { label: "Last month", value: customDateRangeEnum.lastMonth },
  { label: "Last year", value: customDateRangeEnum.lastYear },
  { label: "Custom", value: customDateRangeEnum.custom },
];

/* Returns a date range from the start of N days ago to the end of yesterday */
export const getLastNDaysDateRange = (days) => {
  return {
    from: moment().subtract(days, "day").startOf("day").toDate(),
    to: moment().subtract(1, "day").endOf("day").toDate(),
  };
};

const getLastNYearDateRange = (year) => {
  return {
    from: moment().subtract(year, "year").startOf("year").toDate(),
    to: moment().subtract(1, "year").endOf("year").toDate(),
  };
};

/* Returns a date range from the start of N months ago to the end of last month */
export const getLastNMonthDateRange = (months) => {
  return {
    from: moment().subtract(months, "months").startOf("month").toDate(),
    to: moment().subtract(1, "months").endOf("month").toDate(),
  };
};

/* Returns a date range from the start of N weeks ago to the end of last week */
export const getLastNWeekDateRange = (weeks) => {
  return {
    from: moment().subtract(weeks, "weeks").startOf("isoWeek").toDate(),
    to: moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
  };
};

export const getDateRange = (value) => {
  switch (value) {
    case customDateRangeEnum.last24Hours: {
      return {
        from: moment().subtract(24, "hours"),
        to: moment(),
      };
    }
    case customDateRangeEnum.lastWeek: {
      return getLastNWeekDateRange(1);
    }
    case customDateRangeEnum.lastMonth: {
      return getLastNMonthDateRange(1);
    }
    case customDateRangeEnum.lastYear: {
      return getLastNYearDateRange(1);
    }
    case customDateRangeEnum.custom: {
      return getLastNDaysDateRange(7);
    }

    default: {
      return;
    }
  }
};

export const formatDuration = (seconds) => {
  if (!seconds) return "-";

  const duration = moment.duration(seconds, "s");
  const hour = duration.asHours();
  const day = duration.asDays();
  const option = { trim: "both" };

  if (hour < 1) {
    return duration.format("m[m] s[s]", option);
  } else if (hour >= 1 && hour < 49) {
    return duration.format("h[h] m[m]", option);
  } else if (hour >= 49 && day < 100) {
    return duration.format("d[d] h[h]", option);
  } else {
    return duration.format("M[M] d[d]", option);
  }
};

/* Function used to display to user how long a conversation has been active */
export const formatConversationActiveTime = ({ created, resolvedAt }) => {
  if (!created) return null;

  const createdTime = moment(created);
  const resolvedTime = resolvedAt ? moment(resolvedAt) : moment();
  const diff = moment.duration(resolvedTime.diff(createdTime));

  const diffInDays = diff.asDays().toFixed(2);

  if (diffInDays >= 30) {
    return diff.format("M[m]D[d]");
  }

  if (diffInDays >= 7) {
    if (diffInDays % 7 < 1) return diff.format("w[w]");
    return diff.format("w[w]D[d]");
  }

  if (diff.asHours() >= 24) {
    if (diff.asHours() % 24 < 1) return diff.format("D[d]");
    return diff.format("D[d]h[h]");
  }

  return diff.format("h[h]m[m]");
};

/*
  FormatPreferences lets us customize the final format
  result.

  Common references:
  {
    day: "DD",
    month: "MM",
    year: "YYYY",
    hour: "hh",
    minute: "mm",
    dayPeriod: "A", (AM/PM)
  }
  
  Useful literal preferences (format sequence connectors):
  {  
    literal: " ",
    dateLiteral: "-", (Custom)
    timeLiteral: ":", (Custom)
  }

  - DateLiteral and timeLiteral are custom preferences
    in case we display both time and date but with 
    different literals.
*/
export const getDateFormat = ({
  locale,
  options,
  formatPreferrences = { day: "D", month: "M", year: "YYYY" },
}) => {
  const formatter = new Intl.DateTimeFormat(locale, options).formatToParts();

  const getLiteral = ({ type, value, index }) => {
    const hourFormatPartIndex = formatter.findIndex(
      ({ type }) => type === "hour",
    );

    const isDateLiteral =
      index < hourFormatPartIndex && index + 1 !== hourFormatPartIndex;

    if (isDateLiteral) return formatPreferrences.dateLiteral;

    const isMinuteLiteralFormatPartIndex =
      index === formatter.findIndex(({ type }) => type === "minute") - 1;
    const isSecondLiteralFormatPartIndex =
      index === formatter.findIndex(({ type }) => type === "second") - 1;

    if (isMinuteLiteralFormatPartIndex || isSecondLiteralFormatPartIndex)
      return formatPreferrences.timeLiteral;

    return formatPreferrences[type] || value;
  };

  return formatter
    .map(({ type, value }, index) => {
      if (type === "literal") return getLiteral({ type, value, index });
      return formatPreferrences[type] || value;
    })
    .join("");
};

export const getWeeksAgoTimestampFormat = ({
  timestamp,
  options = {},
  formatPreferrences,
}) => {
  if (window && window.Intl && window.Intl.DateTimeFormat) {
    const timestampYear = moment(timestamp).year();
    const currentYear = moment().year();
    const isCurrentYear = timestampYear === currentYear;

    const locale = window.navigator.language;

    /* 
      DateStyle and timeStyle can only be used with each other:
      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#parameters
    */
    const newOptions =
      options.dateStyle || options.timeStyle
        ? options
        : {
            day: "numeric",
            month: "numeric",
            ...(!isCurrentYear && { year: "numeric" }),
            ...options,
          };

    const dateFormat = getDateFormat({
      locale,
      options: newOptions,
      formatPreferrences,
    });

    return moment(timestamp).locale(locale).format(dateFormat);
  }

  /* Fallback in case some browser doesn't support Intl API */
  return moment(timestamp).format("l");
};

export const getShortFormattedEventTimestamp = (timestamp) => {
  const eventMoment = moment(timestamp);
  const now = moment();

  const date = new Date(timestamp);

  const isToday = eventMoment.isSame(now, "day");
  if (isToday) {
    return new Intl.DateTimeFormat(undefined, {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }).format(date);
  }

  const diffInDays = moment.duration(now.diff(eventMoment)).asDays();
  if (diffInDays < 7) {
    return new Intl.DateTimeFormat(undefined, { weekday: "short" }).format(
      date,
    );
  }

  const isSameYear = now.isSame(eventMoment, "year");
  if (isSameYear) {
    return new Intl.DateTimeFormat(undefined, {
      day: "numeric",
      month: "numeric",
    }).format(date);
  }

  return new Intl.DateTimeFormat(undefined, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  }).format(date);
};
