import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { systemAgentStatusEnum } from "@/enums/agentStatusEnum";

import Available from "@/assets/images/available.svg";
import Away from "@/assets/images/away.svg";
import VoiceOnly from "@/assets/images/call-only.svg";
import Busy from "@/assets/images/dnd.svg";
import NoVoice from "@/assets/images/no-call.svg";
import NoChat from "@/assets/images/no-chat.svg";
import NoVideo from "@/assets/images/no-video.svg";
import NotAvailable from "@/assets/images/not-available.svg";
import Offline from "@/assets/images/offline.svg";
import ChatOnly from "@/assets/images/text-only.svg";
import VideoOnly from "@/assets/images/video-only.svg";

const useStyles = makeStyles((theme) => ({
  availableIcon: {
    width: "14px",
    height: "14px",
    "&.isSmall": {
      width: "6px",
      height: "6px",
      backgroundColor: theme.palette.success[1700],
      borderRadius: "50%",
    },
  },

  awayIcon: {
    width: "14px",
    height: "14px",
    "&.isSmall": {
      width: "6px",
      height: "6px",
      backgroundColor: theme.palette.tertiary[800],
      borderRadius: "50%",
    },
  },

  dndIcon: {
    width: "14px",
    height: "14px",
    "&.isSmall": {
      width: "6px",
      height: "6px",
      backgroundColor: theme.palette.alert[450],
      borderRadius: "50%",
    },
  },

  offlineIcon: {
    "&.isSmall": {
      width: "6px",
      height: "6px",
      border: `1px solid ${theme.palette.primaryBackground.darkest}`,
      borderRadius: "50%",
    },
  },

  chatOnlyIcon: {
    position: "absolute",
    top: "5%",
    left: "3%",
  },
}));

/* TODO: MSG-4163 Remove default value of isVideoFeatureEnabled (true) & pass the relevant props once the isVideoFeatureEnabled feature flag is added in backend */
const AvailableIcon = ({
  canChat,
  canVoiceCall,
  canVideoCall,
  isVoiceFeatureEnabled,
  isVideoFeatureEnabled = true,
}) => {
  const classes = useStyles();

  const isVoiceCallAvailable = isVoiceFeatureEnabled ? canVoiceCall : true;
  const isVoiceCallUnavailable = isVoiceFeatureEnabled ? !canVoiceCall : true;
  const isVideoCallAvailable = isVideoFeatureEnabled ? canVideoCall : true;
  const isVideoCallUnavailable = isVideoFeatureEnabled ? !canVideoCall : true;

  const allUnavailable =
    !canChat && isVoiceCallUnavailable && isVideoCallUnavailable;
  const allAvailable = canChat && isVoiceCallAvailable && isVideoCallAvailable;
  const noChat = !canChat && isVoiceCallAvailable && isVideoCallAvailable;
  const noVoice = canChat && isVoiceCallUnavailable && isVideoCallAvailable;
  const noVideo = canChat && isVoiceCallAvailable && isVideoCallUnavailable;
  const chatOnly = canChat && isVoiceCallUnavailable && isVideoCallUnavailable;
  const voiceOnly = !canChat && isVoiceCallAvailable && isVideoCallUnavailable;
  const videoOnly = !canChat && isVoiceCallUnavailable && isVideoCallAvailable;

  if (allUnavailable) {
    return <NotAvailable className={classes.availableIcon} />;
  } else if (allAvailable) {
    return <Available className={classes.availableIcon} />;
  } else if (chatOnly) {
    return (
      <ChatOnly className={clsx(classes.availableIcon, classes.chatOnlyIcon)} />
    );
  } else if (voiceOnly) {
    return <VoiceOnly className={classes.availableIcon} />;
  } else if (videoOnly) {
    return <VideoOnly className={classes.availableIcon} />;
  } else if (noChat) {
    return <NoChat className={classes.availableIcon} />;
  } else if (noVoice) {
    return <NoVoice className={classes.availableIcon} />;
  } else if (noVideo) {
    return <NoVideo className={classes.availableIcon} />;
  } else {
    return null;
  }
};

const icons = {
  available: AvailableIcon,
  dnd: Busy,
  away: Away,
  offline: Offline,
};

const SmallIcon = ({ className }) => <div className={className} />;

const StatusIcon = ({
  isSmall = false,
  systemStatus,
  canChat,
  canVoiceCall,
  canVideoCall,
  isVoiceFeatureEnabled,
}) => {
  const classes = useStyles();

  if (!systemStatus) return null;

  switch (systemStatus) {
    case systemAgentStatusEnum.available: {
      const Icon = isSmall ? SmallIcon : icons.available;

      return (
        <Icon
          className={clsx(classes.availableIcon, { isSmall })}
          canChat={canChat}
          canVoiceCall={canVoiceCall}
          canVideoCall={canVideoCall}
          isVoiceFeatureEnabled={isVoiceFeatureEnabled}
        />
      );
    }
    case systemAgentStatusEnum.away: {
      const Icon = isSmall ? SmallIcon : icons.away;

      return <Icon className={clsx(classes.awayIcon, { isSmall })} />;
    }
    case systemAgentStatusEnum.busy || systemAgentStatusEnum.wrapUp: {
      const Icon = isSmall ? SmallIcon : icons.dnd;

      return <Icon className={clsx(classes.dndIcon, { isSmall })} />;
    }
    default: {
      const Icon = isSmall ? SmallIcon : icons.offline;

      return <Icon className={clsx(classes.offlineIcon, { isSmall })} />;
    }
  }
};

export default React.memo(StatusIcon);
