/*
  ⚠ IMPORTANT: This enum is used in 2 places: this repository and msg-click-to-call-browser-extension repository
  If you change this enum, then you must also change the "sharedExternalMessageTypeEnum" in msg-click-to-call-browser-extension
*/
export const clickToCallExtensionMessageDataTypeEnum = Object.freeze({
  NOTIFY_MESSAGE_EVENT_LISTENER_ADDED: "NOTIFY_MESSAGE_EVENT_LISTENER_ADDED",
  SYNC_LOGIN_STATUS: "SYNC_LOGIN_STATUS",
  SYNC_ACTIVE_VOICE_CALL_DATA: "SYNC_ACTIVE_VOICE_CALL_DATA",
  REMOVE_ACTIVE_VOICE_CALL_DATA: "REMOVE_ACTIVE_VOICE_CALL_DATA",

  REQUEST_VPA_DATA: "REQUEST_VPA_DATA",
  RETURN_VPA_DATA_SUCCESS: "RETURN_VPA_DATA_SUCCESS",
  RETURN_VPA_DATA_ERROR: "RETURN_VPA_DATA_ERROR",

  REQUEST_CLICK_TO_CALL_SETTINGS_DATA: "REQUEST_CLICK_TO_CALL_SETTINGS_DATA",
  RETURN_CLICK_TO_CALL_SETTINGS_DATA_SUCCESS:
    "RETURN_CLICK_TO_CALL_SETTINGS_DATA_SUCCESS",

  REQUEST_AUTO_SELECT_OUTBOUND_CALL_SETTINGS_DATA:
    "REQUEST_AUTO_SELECT_OUTBOUND_CALL_SETTINGS_DATA",
  RETURN_AUTO_SELECT_OUTBOUND_CALL_SETTINGS_DATA_SUCCESS:
    "RETURN_AUTO_SELECT_OUTBOUND_CALL_SETTINGS_DATA_SUCCESS",

  REQUEST_START_VOICE_CALL: "REQUEST_START_VOICE_CALL",
  RETURN_START_VOICE_CALL_ERROR: "RETURN_START_VOICE_CALL_ERROR",

  REQUEST_END_VOICE_CALL: "REQUEST_END_VOICE_CALL",
  RETURN_END_VOICE_CALL_ERROR: "RETURN_END_VOICE_CALL_ERROR",

  REQUEST_TOGGLE_MICROPHONE: "REQUEST_TOGGLE_MICROPHONE",
  RETURN_TOGGLE_MICROPHONE_ERROR: "RETURN_TOGGLE_MICROPHONE_ERROR",
});
