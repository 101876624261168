import { useCallback, useEffect, useState } from "react";

const parseLocalStorageJSON = ({ key, value }) => {
  try {
    /* localStorage will return null for non-existent key OR when localStorage.clear() is called */
    if (value === null) return null;

    return JSON.parse(value);
  } catch {
    /*
        There are 2 scenarios where JSON.parse may fail:
        1. If value is a string eg. "undefined", it will throw syntax error
        2. If user do localStorage.setItem(key, {id: 1}) in devtools, it will be saved as a string of "[object Object]" which is invalid
      */
    console.error("JSON parsing failed on", { key, value });
    return null;
  }
};

/*
    This hook allows you to read existing localStorage data, update its value, and subscribe to the changes
    Reference: https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts
  */
const useLocalStorage = ({ key, defaultValue = null }) => {
  const [storedData, setStoredData] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return parseLocalStorageJSON({ key, value: item });
    } catch (error) {
      console.error(`Error reading localStorage key '${key}': ${error}`);
      return defaultValue;
    }
  });

  const setLocalStorage = useCallback(
    (newValue) => {
      try {
        localStorage.setItem(key, JSON.stringify(newValue));
        setStoredData(newValue);
      } catch (error) {
        console.error(`Error setting localStorage key '${key}': ${error}`);
      }
    },
    [key],
  );

  /* Listen to storage event whenever local storage data is changed */
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === key) {
        const parsedValue = parseLocalStorageJSON({
          key,
          value: event.newValue,
        });
        setStoredData(parsedValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  return [storedData, setLocalStorage];
};

export default useLocalStorage;
