export const customDateRangeEnum = Object.freeze({
  custom: "custom",
  lastWeek: "lastWeek",
  lastMonth: "lastMonth",
  last7Days: "last7Days",
  last30Days: "last30Days",
  lastYear: "lastYear",
  last24Hours: "last24Hours",
});

export const dayOfWeekEnum = Object.freeze({
  A_0: "Monday",
  A_1: "Tuesday",
  A_2: "Wednesday",
  A_3: "Thursday",
  A_4: "Friday",
  A_5: "Saturday",
  A_6: "Sunday",
});

export const hourDirection = Object.freeze({
  fromHour: "fromHour",
  toHour: "toHour",
});

export const dateRangeKeyEnum = Object.freeze({
  from: "from",
  to: "to",
});
