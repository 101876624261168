/* Source: Backend @ chat/enums */
export const messageTemplateKindEnum = Object.freeze({
  UNCATEGORIZED: "UNCATEGORIZED",
  AWAY_MESSAGE: "AWAY_MESSAGE",
  CANNED_REPLIES: "CANNED_REPLIES",
  CONVERSATION_TRANSFER: "CONVERSATION_TRANSFER",
  FLOWBUILDER_TIMEOUT: "FLOWBUILDER_TIMEOUT",
  FLOWBUILDER_REMINDER: "FLOWBUILDER_REMINDER",
  FLOWBUILDER_AGENT_ASSIGNED: "FLOWBUILDER_AGENT_ASSIGNED",
  FLOWBUILDER_RETRY: "FLOWBUILDER_RETRY",
  FLOWBUILDER_FALLBACK: "FLOWBUILDER_FALLBACK",
  FLOWBUILDER_ENTRY: "FLOWBUILDER_ENTRY",
  FLOWBUILDER_BLOCK_TIMEOUT: "FLOWBUILDER_BLOCK_TIMEOUT",
  FLOWBUILDER_CONVERSATION_TERMINATED: "FLOWBUILDER_CONVERSATION_TERMINATED",
  CSAT_QUESTION: "CSAT_QUESTION",
  CSAT_ADDITIONAL_FEEDBACK: "CSAT_ADDITIONAL_FEEDBACK",
  CSAT_REPLY_RECORDED: "CSAT_REPLY_RECORDED",
  CRM: "CRM",
  EXTERNAL_HSM: "EXTERNAL_HSM",
  AUTO_RESOLVER: "AUTO_RESOLVER",
  MESSAGE_BLAST: "MESSAGE_BLAST",
  LANDING_PAGE: "LANDING_PAGE",
});

export const messageTemplatePartButtonActionTypeEnum = Object.freeze({
  URL: "URL",
  VISIT_CONTENT: "VISIT_CONTENT",
});
