export const mutationPayloadTypenameEnum = Object.freeze({
  contactMutationPayload: "ContactMutationPayload",
  contactTagMutationPayload: "ContactTagMutationPayload",
  conversationTagMutationPayload: "ConversationTagMutationPayload",
  deleteContactTagMutation: "DeleteContactTagMutation",
  deleteConversationTagMutation: "DeleteConversationTagMutation",
  ruleReorderMutation: "RuleReorderMutation",
  ruleMutationPayload: "RuleMutationPayload",
  setConversationPriority: "SetConversationPriority",
  setConversationUnread: "SetConversationUnread",
  conversationLastReadMutation: "ConversationLastReadMutation",
  flowBlockReorderMutation: "FlowBlockReorderMutation",
  updateConversationAllowedInboxViewPinStateMutation:
    "UpdateConversationAllowedInboxViewPinStateMutation",
  voiceIVRNodeReorderMutation: "VoiceIVRNodeReorderMutation",
});
