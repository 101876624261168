import { useMemo, useState } from "react";

import useInterval from "./useInterval";

const useCountdown = (time) => {
  const [seconds, setSeconds] = useState(time);

  const isCountdownCompleted = useMemo(() => seconds === 0, [seconds]);

  useInterval(
    () => setSeconds((prevSeconds) => prevSeconds - 1),
    isCountdownCompleted ? null : 1000,
  );

  return { countdown: seconds, isCountdownCompleted };
};

export default useCountdown;
