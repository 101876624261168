import {
  clientPermissionEnum,
  rolePermissionEnum,
} from "@/enums/permissionEnum";

class PermissionEnum {
  constructor(permissionNames) {
    permissionNames.forEach((permissionName, index) => {
      const number = 1 << index;
      this[permissionName] = number;
    });
  }

  get permissionEntries() {
    return Object.entries(this);
  }

  getPermissionCombinationNames({ permissionCombinations }) {
    return permissionCombinations.map((permissionCombination) => {
      const permissionCombinationNames = [];

      this.permissionEntries.forEach(([permissionName, permissionValue]) => {
        const overlappingPermission = permissionCombination & permissionValue;

        if (overlappingPermission === 0) return;
        permissionCombinationNames.push(permissionName);
      });

      return permissionCombinationNames;
    });
  }
}

const permissionEnum = new PermissionEnum([
  ...Object.values(rolePermissionEnum),
  ...Object.values(clientPermissionEnum),
]);

export default permissionEnum;
