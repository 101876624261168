import { BroadcastChannel, createLeaderElection } from "broadcast-channel";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { featureLeaderElectorNameEnum } from "@/enums/featureLeaderElectorNameEnum";
import * as hookUtils from "@/utils/hookUtils";
import useFeatureLeaderElector from "./useFeatureLeaderElector";

const LeaderElectionContext = createContext({});

/*
  Important: Leader tab cannot be changed dynamically.
  Leadership can only be passed to another tab when the leader tab is closed/refreshed.
  The tab that is opened after the leader tab will be the new leader once leadership is passed.
*/
const LeaderElectionProvider = ({ children }) => {
  const currentTabId = useMemo(() => uuidv4(), []);

  const [isLeaderTab, setIsLeader] = useState(false);

  const [lastVisibleTabIdStorageData, setLastVisibleTabIdStorageData] =
    hookUtils.useLocalStorage({
      key: "lastVisibleTabIdStorageData",
      defaultValue: currentTabId,
    });

  const isLastVisibleTab = currentTabId === lastVisibleTabIdStorageData;

  const {
    isFeatureLeaderTab: isVoiceFeatureLeaderTab,
    isFeatureUsedInAnotherTab: isVoiceFeatureUsedInAnotherTab,
    handleFeatureLock: handleVoiceFeatureLock,
  } = useFeatureLeaderElector({
    featureName: featureLeaderElectorNameEnum.voiceCall,
    currentTabId,
    isLeaderTab,
    lastVisibleTabIdStorageData,
  });

  const isPageVisible = hookUtils.usePageVisibility();

  const valueRefs = hookUtils.useValueRef({
    currentTabId,
    isLastVisibleTab,
    setLastVisibleTabIdStorageData,
    handleVoiceFeatureLock,
  });

  /* Setup broadcast channel to determine the tab leader */
  useEffect(() => {
    /* Ensure that we use the same channel name for all tabs */
    const channel = new BroadcastChannel("leader-election-broadcast-channel");
    const elector = createLeaderElection(channel);

    /* A promise that is automatically run when it detects leader tab has been closed/refreshed */
    elector
      .awaitLeadership()
      .then(() => {
        setIsLeader(true);
      })
      .catch(() => {});

    /* Handle duplicate leaders on rare occasions. See https://rxdb.info/leader-election.html */
    elector.onduplicate = () => {
      location.reload();
    };

    /* Cleanup by ending leadership & closing channel to prevent memory leaks */
    return () => {
      elector.die();
      channel.close();
      setIsLeader(false);
    };
  }, []);

  /* This effect is responsible for resetting feature electors data in local storage when webclient tab is closed */
  useEffect(() => {
    const handleUnload = () => {
      const {
        isLastVisibleTab,
        setLastVisibleTabIdStorageData,
        handleVoiceFeatureLock,
      } = valueRefs.current;

      if (isLastVisibleTab) setLastVisibleTabIdStorageData(null);

      handleVoiceFeatureLock({ isFeatureInUse: false });
    };

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, [valueRefs]);

  /* Update lastVisibleTabIdStorageData whenever user switched browser tab */
  useEffect(() => {
    if (!isPageVisible) return;

    const { currentTabId, setLastVisibleTabIdStorageData } = valueRefs.current;

    /* Prevent race condition on rapid tab switching by adding slight delay before updating lastVisibleTabIdStorageData only when the tab is visible */
    const timer = setTimeout(() => {
      setLastVisibleTabIdStorageData(currentTabId);
    }, 100);

    return () => clearTimeout(timer);
  }, [valueRefs, isPageVisible]);

  const leaderElectionContextValue = useMemo(() => {
    return {
      isLeaderTab,
      isVoiceFeatureLeaderTab,
      isVoiceFeatureUsedInAnotherTab,
      onVoiceFeatureLock: handleVoiceFeatureLock,
    };
  }, [
    isLeaderTab,
    isVoiceFeatureLeaderTab,
    isVoiceFeatureUsedInAnotherTab,
    handleVoiceFeatureLock,
  ]);

  return (
    <LeaderElectionContext.Provider value={leaderElectionContextValue}>
      {children}
    </LeaderElectionContext.Provider>
  );
};

export const useLeaderElection = () => useContext(LeaderElectionContext);

export default LeaderElectionProvider;
