import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import * as userActions from "@/actions/userActions";
import PageLoading from "@/components/PageLoading";
import { wsConnectionStatusEnum } from "@/enums/networkEnum";
import { clientPermissionEnum } from "@/enums/permissionEnum";
import { clickToCallExtensionMessageDataTypeEnum } from "@/enums/voiceCall/clickToCallExtensionMessageDataTypeEnum";
import permissionEnum from "@/helpers/PermissionEnumHelper";
import UserPermissions from "@/helpers/UserPermissionsHelper";
import * as featurePlanQueries from "@/queries/featurePlanQueries";
import * as userQueries from "@/queries/userQueries";
import * as userSelectors from "@/selectors/userSelectors";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import { useValueRef } from "@/utils/hookUtils";
import { getRolesPermissionCodes } from "@/utils/rolesUtils";
import { useRouter } from "next/router";
import { useClientNetworkContext } from "./ClientNetworkProvider/ClientNetworkProvider";
import { useLeaderElection } from "./LeaderElectionProvider";
import { useServiceStatus } from "./ServiceStatusProvider";

const AuthContext = createContext({});
const AuthenticatedUserContext = createContext({});

const AuthProvider = ({ children }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { isVoiceFeatureLeaderTab } = useLeaderElection();
  const { onFetchServiceStatusData } = useServiceStatus();
  const { wsConnectionStatus } = useClientNetworkContext();

  const accessToken = useSelector(userSelectors.accessToken);

  const { data: meQueryData, loading: meQueryLoading } = useMeQuery({
    queryOptions: {
      skip: false,
      onError: () => handleSignOut(),
    },
  });

  const {
    id: userId,
    isHidden: isHiddenSupportUser,
    roles,
  } = meQueryData?.me || {};

  const isUserLoggedIn = Boolean(accessToken && userId);

  const { data: additionalUserData, loading: additionalUserDataLoading } =
    useQuery(userQueries.GET_ADDITIONAL_USER_DATA, {
      skip: !isUserLoggedIn,
      onError: () => handleSignOut(),
    });

  const { data: featurePlansQueryData, loading: featurePlansLoading } =
    useQuery(featurePlanQueries.GET_VOICE_FEATURE_PLAN, {
      skip: !isUserLoggedIn,
      onError: () => handleSignOut(),
    });

  const handleSignOut = useCallback(async () => {
    dispatch(userActions.logOutRequest());
    onFetchServiceStatusData({ isSignOut: true });
  }, [dispatch, onFetchServiceStatusData]);

  const isUserDataLoading =
    meQueryLoading || additionalUserDataLoading || featurePlansLoading;

  const clientPermissions = useMemo(() => {
    const permissions = [];

    const hasMessagingProviderAccounts = !isEmpty(
      additionalUserData?.messagingProviderAccounts,
    );

    const { isVoiceFeatureEnabled } = featurePlansQueryData?.featurePlan || {};

    if (hasMessagingProviderAccounts) {
      permissions.push(clientPermissionEnum.ACCESS_CHAT_FEATURE);
    }

    if (isVoiceFeatureEnabled) {
      permissions.push(clientPermissionEnum.ACCESS_VOICE_FEATURE);
    }

    return permissions;
  }, [
    additionalUserData?.messagingProviderAccounts,
    featurePlansQueryData?.featurePlan,
  ]);

  const userPermissionCodes = useMemo(() => {
    if (isUserDataLoading) return null;
    if (isEmpty(roles)) return clientPermissions;
    return [...getRolesPermissionCodes(roles), ...clientPermissions];
  }, [roles, clientPermissions, isUserDataLoading]);

  const valueRefs = useValueRef({
    router,
    isVoiceFeatureLeaderTab,
  });

  /* Multi-tab logout */
  useEffect(() => {
    const callback = (e) => {
      if (localStorage.length === 0) {
        valueRefs.current.router.reload();
      }

      if (e.key === "token" && e.oldValue && !e.newValue) {
        dispatch(userActions.logOutRequest());
      }
    };

    window.addEventListener("storage", callback);

    return () => {
      window.removeEventListener("storage", callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRefs]);

  useEffect(() => {
    if (!valueRefs.current.isVoiceFeatureLeaderTab) return;

    window.postMessage(
      {
        type: clickToCallExtensionMessageDataTypeEnum.SYNC_LOGIN_STATUS,
        isUserLoggedIn,
      },
      window.location.origin,
    );
  }, [valueRefs, isUserLoggedIn]);

  const isWsConnectionLost = useMemo(() => {
    return (
      accessToken &&
      [
        wsConnectionStatusEnum.closed,
        wsConnectionStatusEnum.reconnecting,
      ].includes(wsConnectionStatus)
    );
  }, [wsConnectionStatus, accessToken]);

  const messagingProvidersInUse = useMemo(() => {
    return additionalUserData?.messagingProviders || [];
  }, [additionalUserData?.messagingProviders]);

  const mpaCountPerMpMap = useMemo(() => {
    if (!additionalUserData?.messagingProviderAccounts) return {};

    return additionalUserData.messagingProviderAccounts.reduce((acc, val) => {
      const { messagingProvider } = val;
      const key = messagingProvider.id;
      const newVal = acc[key] ? acc[key] + 1 : 1;

      return { ...acc, [key]: newVal };
    }, {});
  }, [additionalUserData?.messagingProviderAccounts]);

  const userPermissionCodesSet = useMemo(() => {
    if (!userPermissionCodes) return new Set();
    return new Set(userPermissionCodes);
  }, [userPermissionCodes]);

  const isWsAttemptingToReconnect =
    wsConnectionStatus === wsConnectionStatusEnum.reconnecting;

  const userPermissionsObject = useMemo(() => {
    return new UserPermissions({ userPermissionCodesSet });
  }, [userPermissionCodesSet]);

  const shouldMaskPhoneNumber = useMemo(() => {
    return !userPermissionsObject.hasPermission(
      permissionEnum.VIEW_FULL_PHONE_NUMBER,
    );
  }, [userPermissionsObject]);

  const authContextValue = useMemo(() => {
    return {
      isUserLoggedIn,
      isUserDataLoading,
      isWsConnectionLost,
      isWsAttemptingToReconnect,
      onSignOut: handleSignOut,
    };
  }, [
    isUserLoggedIn,
    isUserDataLoading,
    isWsConnectionLost,
    isWsAttemptingToReconnect,
    handleSignOut,
  ]);

  const authenticatedUserContextValue = useMemo(() => {
    return {
      isHiddenSupportUser,
      userPermissionsObject,
      shouldMaskPhoneNumber,
      messagingProvidersInUse,
      mpaCountPerMpMap,
      messagingProviderAccounts: additionalUserData?.messagingProviderAccounts,
    };
  }, [
    isHiddenSupportUser,
    userPermissionsObject,
    shouldMaskPhoneNumber,
    messagingProvidersInUse,
    mpaCountPerMpMap,
    additionalUserData?.messagingProviderAccounts,
  ]);

  if (isUserDataLoading) return <PageLoading />;
  return (
    <AuthContext.Provider value={authContextValue}>
      <AuthenticatedUserContext.Provider value={authenticatedUserContextValue}>
        {children}
      </AuthenticatedUserContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export const useAuthenticatedUserContext = () =>
  useContext(AuthenticatedUserContext);

export default AuthProvider;
