import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";

import { getConversationEventUnionObjectLabel } from "@/utils/inboxPageUtils";
import MentionPrefix, { mentionPrefixEnum } from "./MentionPrefix";

const useStyles = makeStyles((theme) => ({
  mentionText: {
    color: theme.palette.link.main,

    "&.isTooltipTitle": {
      color: theme.palette.common.white,
    },

    "&.isStatusIconPrefix": {
      display: "inline-flex",
      alignItems: "center",
      gap: "4px",
    },

    "&.isInactive": {
      color: theme.palette.grey[1800],
    },
  },

  tooltip: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "5px 10px",
    fontSize: "1rem",
    gap: "10px",
    maxWidth: "560px",
  },

  groupText: {
    fontStyle: "italic",
  },
}));

const TooltipTitle = ({ agent, isActive }) => {
  const classes = useStyles();

  const { groups } = agent;
  const groupText = groups.map((group) => group.name).join(", ");

  if (!isActive) {
    return <>User account removed</>;
  }

  return (
    <>
      <div
        className={clsx(classes.mentionText, {
          isTooltipTitle: true,
          isStatusIconPrefix: true,
        })}
      >
        <MentionPrefix
          prefixType={mentionPrefixEnum.statusIcon}
          agent={agent}
        />

        {getConversationEventUnionObjectLabel(agent)}
      </div>

      <div className={classes.groupText}>{groupText}</div>
    </>
  );
};

const MentionRenderer = ({
  children,
  mention,
  isPrefixShown = true,
  prefixType = mentionPrefixEnum.statusIcon,
  TooltipProps = {},
}) => {
  const classes = useStyles();

  const { isActive } = mention.targetAgent;

  return (
    <Tooltip
      title={<TooltipTitle agent={mention.targetAgent} isActive={isActive} />}
      placement="top"
      arrow
      classes={{ tooltip: classes.tooltip }}
      {...TooltipProps}
    >
      <span
        className={clsx(classes.mentionText, {
          isInactive: !isActive,
          isStatusIconPrefix: prefixType === mentionPrefixEnum.statusIcon,
        })}
      >
        {isPrefixShown && (
          <MentionPrefix prefixType={prefixType} agent={mention.targetAgent} />
        )}
        {children}
      </span>
    </Tooltip>
  );
};

export default MentionRenderer;
