import { gql } from "@apollo/client";

import * as conversationReportingDefinitions from "@/definitions/reporting/conversationReportingDefinitions";

export const GET_AGENT = gql`
  query GetAgent($id: ID) {
    agents(id: $id) {
      results {
        id
        user {
          email
          firstName
          lastName
        }
        groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_AGENTS = gql`
  query GetAgents($nameSearch: String) {
    agents(nameSearch: $nameSearch) {
      totalCount
      results {
        id
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  query GetGroup($id: ID) {
    groups(id: $id) {
      id
      name
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroups($nameSearch: String) {
    groups(nameSearch: $nameSearch, orderBy: "name") {
      id
      name
    }
  }
`;

export const GET_TEAM_REPORT_OVERVIEW_DATA = gql`
  query GetTeamReportOverviewData(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team ${conversationReportingDefinitions.reportOverviewDataDefinition}
    }
  }
`;

export const GET_GROUP_REPORT_OVERVIEW_DATA = gql`
  query GetGroupReportOverviewData(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id)
      ${conversationReportingDefinitions.reportOverviewDataDefinition}
    }
  }
`;

export const GET_AGENT_REPORT_OVERVIEW_DATA = gql`
  query GetAgentReportOverviewData(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id)
      ${conversationReportingDefinitions.reportOverviewDataDefinition}
    }
  }
`;

export const GET_GROUP_AGENT_REPORT_OVERVIEW_DATA = gql`
  query GetGroupAgentReportOverviewData(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId)
        ${conversationReportingDefinitions.reportOverviewDataDefinition}
      }
    }
  }
`;

export const GET_TEAM_TOTAL_NEW_CONVERSATIONS = gql`
  query GetTeamTotalNewConversations(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team {
        ${conversationReportingDefinitions.totalNewConversationsDefinition}  
      }
    }
  }
`;

export const GET_GROUP_TOTAL_NEW_CONVERSATIONS = gql`
  query GetGroupTotalNewConversations(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.totalNewConversationsDefinition}
      }
    }
  }
`;

export const GET_AGENT_TOTAL_NEW_CONVERSATIONS = gql`
  query GetAgentTotalNewConversations(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.totalNewConversationsDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_TOTAL_NEW_CONVERSATIONS = gql`
  query GetGroupAgentTotalNewConversations(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.totalNewConversationsDefinition}
        }
      }
    }
  }
`;

export const GET_TEAM_TOTAL_MESSAGES = gql`
  query GetTeamTotalMessages(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team {
        ${conversationReportingDefinitions.totalMessagesDefinition}
      }
    }
  }
`;

export const GET_GROUP_TOTAL_MESSAGES = gql`
  query GetGroupTotalMessages(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.totalMessagesDefinition}
      }
    }
  }
`;

export const GET_AGENT_TOTAL_MESSAGES = gql`
  query GetAgentTotalMessages(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.totalMessagesDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_TOTAL_MESSAGES = gql`
  query GetGroupAgentTotalMessages(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.totalMessagesDefinition}
        }
      }
    }
  }
`;

export const GET_TEAM_AVERAGE_MESSAGES_PER_CONVERSATION = gql`
  query GetTeamAverageMessagesPerConversation(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team {
        ${conversationReportingDefinitions.averageMessagesPerConversationDefinition}
      }
    }
  }
`;

export const GET_GROUP_AVERAGE_MESSAGES_PER_CONVERSATION = gql`
  query GetGroupAverageMessagesPerConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.averageMessagesPerConversationDefinition}
      }
    }
  }
`;

export const GET_AGENT_AVERAGE_MESSAGES_PER_CONVERSATION = gql`
  query GetAgentAverageMessagesPerConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.averageMessagesPerConversationDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_AVERAGE_MESSAGES_PER_CONVERSATION = gql`
  query GetGroupAgentAverageMessagesPerConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.averageMessagesPerConversationDefinition}
        }
      }
    }
  }
`;

export const GET_TEAM_FIRST_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetTeamFirstResponseTimeIncludeWorkingHour(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team {
        ${conversationReportingDefinitions.firstResponseTimeIncludeWorkingHourDefinition}
      }
    }
  }
`;

export const GET_GROUP_FIRST_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetGroupFirstResponseTimeIncludeWorkingHour(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.firstResponseTimeIncludeWorkingHourDefinition}
      }
    }
  }
`;

export const GET_AGENT_FIRST_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetAgentFirstResponseTimeIncludeWorkingHour(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.firstResponseTimeIncludeWorkingHourDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_FIRST_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetGroupAgentFirstResponseTimeIncludeWorkingHour(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.firstResponseTimeIncludeWorkingHourDefinition}
        }
      }
    }
  }
`;

export const GET_TEAM_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetTeamResponseTimeIncludeWorkingHour(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team {
        ${conversationReportingDefinitions.responseTimeIncludeWorkingHourDefinition}
      }
    }
  } 
`;

export const GET_GROUP_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetGroupResponseTimeIncludeWorkingHour(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.responseTimeIncludeWorkingHourDefinition}
      }
    }
  }
`;

export const GET_AGENT_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetAgentResponseTimeIncludeWorkingHour(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.responseTimeIncludeWorkingHourDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_RESPONSE_TIME_INCLUDE_WORKING_HOUR = gql`
  query GetGroupAgentResponseTimeIncludeWorkingHour(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.responseTimeIncludeWorkingHourDefinition}
        }
      }
    }
  }
`;

export const GET_TEAM_AGENT_RESOLUTION_TIME = gql`
  query GetTeamAgentResolutionTime(${conversationReportingDefinitions.conversationReportVarDefinition}) {
    reporting {
      team {
        ${conversationReportingDefinitions.agentResolutionTimeDefinition}
      }
    }
  }
`;

export const GET_GROUP_RESOLUTION_TIME = gql`
  query GetGroupResolutionTime(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.agentResolutionTimeDefinition}
      }
    }
  }
`;

export const GET_AGENT_RESOLUTION_TIME = gql`
  query GetAgentResolutionTime(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.agentResolutionTimeDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_RESOLUTION_TIME = gql`
  query GetGroupAgentResolutionTime(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.agentResolutionTimeDefinition}
        }
      }
    }
  }
`;

export const GET_TEAM_AVERAGE_CSAT = gql`
  query GetTeamAverageCSAT(
    ${conversationReportingDefinitions.conversationReportVarDefinition} 
    ${conversationReportingDefinitions.csatFilterVarDefinition}
  ) {
    reporting {
      team {
        ${conversationReportingDefinitions.csatReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AVERAGE_CSAT = gql`
  query GetGroupAverageCSAT(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    ${conversationReportingDefinitions.csatFilterVarDefinition}
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.csatReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_AVERAGE_CSAT = gql`
  query GetGroupAgentAverageCSAT(
    ${conversationReportingDefinitions.conversationReportVarDefinition},
    ${conversationReportingDefinitions.csatFilterVarDefinition}
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.csatReportDefinition}
        }
      }
    }
  }
`;

export const GET_AGENT_AVERAGE_CSAT = gql`
  query GetAgentAverageCSAT(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    ${conversationReportingDefinitions.csatFilterVarDefinition}
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.csatReportDefinition}
      }
    }
  }
`;

export const GET_TEAM_UNATTENDED_CONVERSATION = gql`
  query GetTeamUnattendedConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
  ) {
    reporting {
      team {
        ${conversationReportingDefinitions.unattendedConversationReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_UNATTENDED_CONVERSATION = gql`
  query GetGroupUnattendedConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $id: ID
  ) {
    reporting {
      groups (id: $id) {
        ${conversationReportingDefinitions.unattendedConversationReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_UNATTENDED_CONVERSATION = gql`
  query GetGroupAgentUnattendedConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.unattendedConversationReportDefinition}
        }
      }
    }
  }
`;

export const GET_AGENT_UNATTENDED_CONVERSATION = gql`
  query GetAgentUnattendedConversation(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $id: ID
  ) {
    reporting {
      agents (id: $id) {
        ${conversationReportingDefinitions.unattendedConversationReportDefinition}
      }
    }
  }
`;

/* Queries for <CSATReportTable> */
export const GET_TEAM_CSAT_REPORT = gql`
  query GetTeamCSATReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    ${conversationReportingDefinitions.csatFilterVarDefinition}
  ) {
    reporting {
      team {
        ${conversationReportingDefinitions.csatReportDataDefinition}
      }
    }
  }
`;

export const GET_GROUP_CSAT_REPORT = gql`
  query GetGroupCSATReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    ${conversationReportingDefinitions.csatFilterVarDefinition}
    $id: ID
  ) {
    reporting {
      groups(
        id: $id
        ) {
          id
          name
          ${conversationReportingDefinitions.csatReportDataDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_CSAT_REPORT = gql`
  query GetGroupAgentCSATReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    ${conversationReportingDefinitions.csatFilterVarDefinition}
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${conversationReportingDefinitions.csatReportDataDefinition}
        }
      }
    }
  }
`;

export const GET_AGENT_CSAT_REPORT = gql`
  query GetAgentCSATReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    ${conversationReportingDefinitions.csatFilterVarDefinition}
    $id: ID
  ) {
    reporting {
      agents(
        id: $id
        ) {
          ${conversationReportingDefinitions.csatReportDataDefinition}
      }
    }
  }
`;

/* Queries for <UnattendedReportTable> */
export const GET_TEAM_UNATTENDED_CONVERSATION_REPORT = gql`
  query GetTeamUnattendedConversationReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
  ) {
    reporting {
      team {
        ${conversationReportingDefinitions.unattendedConversationReportDataDefinition}
      }
    }
  }
`;

export const GET_GROUP_UNATTENDED_CONVERSATION_REPORT = gql`
  query GetGroupUnattendedConversationReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $id: ID
  ) {
    reporting {
      groups(
        id: $id
      ) {
        ${conversationReportingDefinitions.unattendedConversationReportDataDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_UNATTENDED_CONVERSATION_REPORT = gql`
  query GetGroupAgentUnattendedConversationReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $groupId: ID
    $agentId: ID
  ) {
    reporting {
      groups (id: $groupId) {
        agents (
          id: $agentId
          ) {
          ${conversationReportingDefinitions.unattendedConversationReportDataDefinition}
        }
      }
    }
  }
`;

export const GET_AGENT_UNATTENDED_CONVERSATION_REPORT = gql`
  query GetAgentUnattendedConversationReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $id: ID
  ) {
    reporting {
      agents(
        id: $id
      ) {
        ${conversationReportingDefinitions.unattendedConversationReportDataDefinition}
      }
    }
  }
`;

/* Queries for <ConversationReportTable> in <GroupSection> */
export const GET_GROUPS_REPORT = gql`
  query GetGroupsReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $id: ID
    $limit: Int
    $offset: Int
  ) {
    reporting {
      groups(
        id: $id
        limit: $limit
        offset: $offset
        ) {
          name
          ${conversationReportingDefinitions.conversationReportTableDataDefinition}
      }
    }
  }
`;

/* Queries for <ConversationReportTable> in <AgentSection> */
export const GET_AGENTS_REPORT = gql`
  query GetAgentsReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $limit: Int
    $offset: Int
  ) {
    reporting {
      agents(
        limit: $limit
        offset: $offset
      ) {
        user {
          firstName
          lastName
          email
        }
        ${conversationReportingDefinitions.conversationReportTableDataDefinition}
      }
    }
  }
`;

/* Queries for <ConversationReportTable> in <GroupChartWithTable> */
export const GET_GROUP_AGENT_REPORT = gql`
  query GetGroupAgentReport(
    ${conversationReportingDefinitions.conversationReportVarDefinition}
    $id: ID
    $limit: Int
    $offset: Int
  ) {
    reporting {
      groups(
        id: $id
        limit: $limit
        offset: $offset
      ){
        agents {
          user {
            firstName
            lastName
            email
          }
          ${conversationReportingDefinitions.conversationReportTableDataDefinition}
        }
      }
    }
  }
`;

export const GET_CONTENT_TYPE_ALLOWED_TAGS = gql`
  query ContentTypeAllowedTags($contentType: String, $nameSearch: String) {
    contentTypeAllowedTags(contentType: $contentType, nameSearch: $nameSearch) {
      contentTypeAllowedTagId: id
      tag {
        name
      }
    }
  }
`;
