import { Backdrop, alpha, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  pageLoadingContainer: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "&.showAsOverlay": {
      position: "absolute",
      zIndex: 20,
      opacity: 0.5,
      background: alpha(theme.palette.common.white, 0.5),
    },
  },
}));

const PageLoadingContainer = ({ children, showAsOverlay, ...otherProps }) => {
  if (showAsOverlay)
    return (
      <Backdrop open invisible {...otherProps}>
        {children}
      </Backdrop>
    );

  return <div {...otherProps}>{children}</div>;
};

const PageLoading = ({
  size,
  containerClassName,
  showAsOverlay,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <PageLoadingContainer
      showAsOverlay={showAsOverlay}
      className={clsx(classes.pageLoadingContainer, containerClassName, {
        showAsOverlay,
      })}
    >
      <CircularProgress size={size} {...otherProps} />
    </PageLoadingContainer>
  );
};

export default PageLoading;
