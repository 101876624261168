import { gql } from "@apollo/client";

export const GET_VOICE_FEATURE_PLAN = gql`
  query GetVoiceFeaturePlan {
    featurePlan {
      isVoiceFeatureEnabled
    }
  }
`;

export const GET_VOICE_RECORDING_FEATURE_PLAN = gql`
  query GetVoiceRecordingFeaturePlan {
    featurePlan {
      isVoiceRecordingFeatureEnabled
    }
  }
`;

export const GET_MESSAGE_BLAST_FEATURE_PLAN = gql`
  query GetMessageBlastFeaturePlan {
    featurePlan {
      messageBlast {
        successfulTotalBlastsSentPastMonth
        limit
      }
    }
  }
`;

export const GET_RULES_FEATURE_PLAN = gql`
  query GetRulesFeaturePlan {
    featurePlan {
      rule {
        totalActiveRules
        limit
      }
    }
  }
`;
