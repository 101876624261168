import * as allVoiceCallReportQueries from "./allVoiceCallReportQueries";
import * as inboundVoiceCallReportQueries from "./inboundVoiceCallReportQueries";
import * as outboundVoiceCallReportQueries from "./outboundVoiceCallReportQueries";
import * as voiceCallLogsQueries from "./voiceCallLogsQueries";
import * as voiceReportingQueries from "./voiceReportingQueries";

export default {
  ...allVoiceCallReportQueries,
  ...inboundVoiceCallReportQueries,
  ...outboundVoiceCallReportQueries,
  ...voiceCallLogsQueries,
  ...voiceReportingQueries,
};
