import { isNil, omitBy } from "lodash";
import { useRouter } from "next/router";
import { useCallback } from "react";

import useValueRef from "./useValueRef";

const useHandlePageQueryChange = () => {
  const router = useRouter();
  const routerRef = useValueRef(router);

  const handleRouteChange = useCallback(
    ({
      shouldReplacePath = false,
      shouldReplaceQuery = false,
      pathname,
      queryProps = {},
    }) => {
      const router = routerRef.current;

      const newQueryObject = shouldReplaceQuery
        ? queryProps
        : { ...router.query, ...queryProps };

      const finalQueryObject = omitBy(newQueryObject, isNil);
      const routerOperation = shouldReplacePath ? router.replace : router.push;

      routerOperation(
        { pathname: pathname || router.pathname, query: finalQueryObject },
        undefined,
        { shallow: true },
      );
    },
    [routerRef],
  );

  return handleRouteChange;
};

export default useHandlePageQueryChange;
