/* This enum is used by frontend only to help identify different message types rendered in the inbox page */
export const chatMessageTypeEnum = Object.freeze({
  image: "IMAGE",
  video: "VIDEO",
  sticker: "STICKER",
  file: "FILE",
  audio: "AUDIO",
  text: "TEXT",
  location: "LOCATION",
  whatsappHsm: "WHATSAPP_HSM",
  unsupported: "UNSUPPORTED",
});

/*
  This enum is obtained from backend and must match the enums in:
  - MediaConversationNoteMediaMessageType
  - MediaConversationMessageMediaMessageType
*/
export const mediaMessageTypeEnum = Object.freeze({
  image: "IMAGE",
  video: "VIDEO",
  sticker: "STICKER",
  file: "FILE",
  audio: "AUDIO",
});

/* This enum is obtained from backend and must only contain values in ConversationMessageContentTypeEnum */
export const conversationMessageContentTypeEnum = Object.freeze({
  text: "TEXT",
  media: "MEDIA",
});

export const messageDirectionEnum = Object.freeze({
  incoming: "INCOMING",
  outgoing: "OUTGOING",
});

export const messageFailedCodeEnum = Object.freeze({
  outsideAllowedWindow: "MESSAGE_OUTSIDE_ALLOWED_WINDOW",
});

export const messageStatusEnum = Object.freeze({
  initial: "INITIAL",
  submitted: "SUBMITTED",
  delivered: "DELIVERED",
  read: "READ",
  deleted: "DELETED",
  failed: "FAILED",
});

export const messageTypeEnum = Object.freeze({
  chat: "CHAT",
  messageBlasting: "MESSAGE_BLASTING",
});

export const mediaTranscriptionStatusEnum = Object.freeze({
  languageIdentificationFailed: "LANGUAGE_IDENTIFICATION_FAILED",
  transcriptionFailed: "TRANSCRIPTION_FAILED",
  done: "DONE",
});
