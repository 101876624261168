import { gql } from "@apollo/client";

import {
  transcriptionSegmentsDefinition,
  voiceCallLogsDefinition,
  voiceCallLogsFormDataDefinition,
} from "@/definitions/reporting/voiceReportingDefinitions";

export const GET_VOICE_CALL_LOGS_FORM_DATA = gql`
  query GetVoiceCallLogsFormData($id: ID) {
    voiceConversations(id: $id) {
      totalCount
      results ${voiceCallLogsFormDataDefinition}
    }
  }
`;

export const GET_TRANSCRIPTION_SEGMENTS = gql`
  query GetTranscriptionSegments($recordingId: ID, $limit: Int, $offset: Int) {
    transcriptionSegments(recordingId: $recordingId, limit: $limit, offset: $offset) {
      totalCount
      results ${transcriptionSegmentsDefinition}
    }
  }
`;

export const GET_ONGOING_VOICE_CALL_LOGS_CSV_EXPORTS_TOTAL_COUNT = gql`
  query GetOngoingVoiceCallLogsCsvExportsTotalCount($isOngoing: Boolean) {
    voiceConversationCsvExports(isOngoing: $isOngoing) {
      totalCount
    }
  }
`;

export const GET_CONTACT_ENTITY = gql`
  query GetContactEntity {
    entities(name: "Contact") {
      id
    }
  }
`;

export const GET_VOICE_CALL_LOGS = gql`
  query GetVoiceCallLogs(
    $createdGte: DateTime
    $createdLt: DateTime
    $callDirection: VoiceConversationCallDirectionEnum
    $conversationStatus: VoiceConversationConversationStatusEnum
    $contactTagIds: [ID]
    $conversationTagIds: [ID]
    $firstAgentInCallIds: [ID]
    $conversationGroupIds: [ID]
    $conversationAgentIds: [ID]
    $activeCallDurationGte: Decimal
    $activeCallDurationLte: Decimal
    $initialSpeedToAnswerGte: Decimal
    $initialSpeedToAnswerLte: Decimal
    $limit: Int
    $offset: Int
  ) {
    voiceConversations (
      createdGte: $createdGte
      createdLt: $createdLt
      callDirection: $callDirection
      conversationStatus: $conversationStatus
      contactTagIds: $contactTagIds
      conversationTagIds: $conversationTagIds
      firstAgentInCallIds: $firstAgentInCallIds
      conversationGroupIds: $conversationGroupIds
      conversationAgentIds: $conversationAgentIds
      activeCallDurationGte: $activeCallDurationGte
      activeCallDurationLte: $activeCallDurationLte
      initialSpeedToAnswerGte: $initialSpeedToAnswerGte
      initialSpeedToAnswerLte: $initialSpeedToAnswerLte
      limit: $limit
      offset: $offset
      orderBy: "-created"
    ) {
      totalCount
      results ${voiceCallLogsDefinition}
    }
  }
`;
