import { gql } from "@apollo/client";

export const sharedPaymentConfigurationFieldDefinition = `
  id
  fieldType
  displayName
  defaultValue
  isRequired
  isShownInReceipt
  maxCharactersLimit
`;

export const whatsappHsmTranslatedTemplatesDefinition = `{
  id
  headerType
  headerTextInHtml
  headerTextParams {
    id
    hint
  }

  bodyInHtml  
  bodyTextParams {
    id
    hint
  }

  footerInHtml

  buttons {
    id
    buttonType
    label
    phoneNumber
  }
}`;

export const paymentSessionDefinition = `{
  id
  created
  status
  paymentConfiguration {
    id
    name
  }

  conversation {
    id
  }

  events (
    limit: 1
    orderBy: "-created"
    ) {
    id
    eventType
  }
}`;

export const PAYMENT_SESSION_FRAGMENT = gql`
  fragment PaymentSession on PaymentSessionObject ${paymentSessionDefinition}
`;
