import { ApolloProvider } from "@apollo/client";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as userActions from "@/actions/userActions";
import { wsConnectionStatusEnum } from "@/enums/networkEnum";
import * as userSelectors from "@/selectors/userSelectors";
import PageLoading from "@/src/components/PageLoading";
import { useValueRef } from "@/src/utils/hookUtils";
import { createApolloClient } from "./apolloClient";
import { createSubscriptionClient } from "./subscriptionClient";

const ClientNetworkContext = createContext({});

const ClientNetworkProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [subscriptionClient, setSubscriptionClient] = useState(null);
  const [apolloClient, setApolloClient] = useState(null);

  const [wsConnectionStatus, setWsConnectionStatus] = useState(
    wsConnectionStatusEnum.initial,
  );

  const accessToken = useSelector(userSelectors.accessToken);

  const handleDisposeSubscriptionClient = () => {
    /* 
      Clean up resources, dispose the subscriptionClient if we have any.
      The close code is "1000: Normal Closure", so no retry attempt will be made.
    */
    subscriptionClient?.dispose();
  };

  const handleCreateApolloClient = ({ subscriptionClient }) => {
    const newApolloClient = createApolloClient({
      accessToken,
      subscriptionClient,
      authErrorCallback: () => {
        dispatch(userActions.logOutRequest());
      },
    });

    return newApolloClient;
  };

  const createClients = () => {
    setWsConnectionStatus(wsConnectionStatusEnum.inProgress);

    handleDisposeSubscriptionClient();

    const connectingCallback = (isRetry) => {
      const status = isRetry
        ? wsConnectionStatusEnum.reconnecting
        : wsConnectionStatusEnum.connecting;

      setWsConnectionStatus(status);
    };

    const connectedCallback = ({ wasRetry, subscriptionClient }) => {
      if (wasRetry) {
        /* Reinitialize apollo client to fetch latest data after reconnection */
        const newApolloClient = handleCreateApolloClient({
          subscriptionClient,
        });

        setApolloClient(newApolloClient);
      }

      setWsConnectionStatus(wsConnectionStatusEnum.connected);
    };

    const closedCallback = () => {
      setWsConnectionStatus(wsConnectionStatusEnum.closed);
    };

    const newSubscriptionClient = createSubscriptionClient({
      accessToken,
      connectingCallback,
      connectedCallback,
      closedCallback,
    });

    const newApolloClient = handleCreateApolloClient({
      subscriptionClient: newSubscriptionClient,
    });

    setSubscriptionClient(newSubscriptionClient);
    setApolloClient(newApolloClient);
  };

  const valueRefs = useValueRef({
    createClients,
    handleDisposeSubscriptionClient,
  });

  /* This effect is responsible for creating the subscriptionClient and apolloClient  */
  useEffect(() => {
    const { createClients } = valueRefs.current;
    createClients();
  }, [valueRefs, accessToken]);

  /* Add event listeners for when the user goes offline and online */
  useEffect(() => {
    const offlineHandler = () => {
      const { handleDisposeSubscriptionClient } = valueRefs.current;
      handleDisposeSubscriptionClient();
    };

    const onlineHandler = () => {
      const { createClients } = valueRefs.current;
      createClients();
    };

    window.addEventListener("offline", offlineHandler);
    window.addEventListener("online", onlineHandler);

    return () => {
      window.removeEventListener("offline", offlineHandler);
      window.removeEventListener("online", onlineHandler);
    };
  }, [valueRefs]);

  const clientNetworkContextValue = useMemo(() => {
    return { wsConnectionStatus };
  }, [wsConnectionStatus]);

  if (!apolloClient) return <PageLoading />;

  return (
    <ApolloProvider client={apolloClient}>
      <ClientNetworkContext.Provider value={clientNetworkContextValue}>
        {children}
      </ClientNetworkContext.Provider>
    </ApolloProvider>
  );
};

export const useClientNetworkContext = () => useContext(ClientNetworkContext);

export default ClientNetworkProvider;
